import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import ImageTextSlider from "../components/pager";
import arrowBack from "../assets/Images/arrow_back_ios_new.png";
import flash from "../assets/Images/flash_white.png";
import like from "../assets/Images/like.png";
import building from "../assets/Images/apap.png";
import refresh from "../assets/Images/refresh.png";
import question from "../assets/Images/question.png";
import pwa_image from "../assets/Images/pwa_image.png";
import contract from "../assets/Images/contract.png";
import forward_arrow from "../assets/Images/forward_arrow.png";
import { makeApiCall } from "../services/ApiService";
import {
  API_CALL_TYPE,
  GET_ACTIVE_SUBSCRIPTION,
  GET_ALL_FINTECHS,
  GET_BILLING_ADDRESSES,
  GET_SUBSCRIPTION_PLANS,
  POST_PAYMENT_METHOD,
  SAVE_SESSION,
  UPDATE_SESSION,
} from "../services/Api";
import { useQueryClient } from "react-query";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import StarRateIcon from "@mui/icons-material/StarRate";
import Swiper from "swiper";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import {
  setSessionData,
  setLoading,
  setError,
  setClickedHeader,
  setDynamicLink,
  SessionState,
  setSessionIDStore,
  setAsaConsumerCode,
} from "../redux/sessionSlice";
import { SessionData, SessionUpdateRequest } from "../types/types";
import { ToastContentProps, toast } from "react-toastify";
import { Config } from "../Utils/AppConstants";
import axios from "axios";
import LoaderComponent from "../components/LoaderComponent";
interface BillingAddress {
  consumerAddressId: number;
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  zipCode: string;
  firstName: string;
  lastName: string;
  country: string;
}

interface PaymentMethod {
  arrow_open: any;
  dropdown_arrow_close: any;
  paymentMethodId: number;
  asaPaymentMethodCode: number;
  isDefault: boolean;
  paymentMethodName: string;
  paymentType: string;
  routingNumber: string | null;
  last4DigitsOfAccountNumber: string | null;
  bankName: string;
  cardNumber: string | null;
  accountNumber: string | null;
  cardType: string | null;
  last4DigitsOfCardNumber: string | null;
  firstName: string | null;
  middleName: string | null;

  lastName: string | null;
  cardHolderName: string | null;
  expirationYear: string | null;
  expirationMonth: string | null;
}

// Interface for a single subscription detail
interface SubscriptionDetail {
  billingDetailCode: number;
  asaConsumerCode: number;
  asaFintechCode: number;
  billingDate: string; // ISO 8601 date format as a string
  billingAmount: number;
  status: string;
  comment: string;
  isSubscription: boolean;
  subscriptionPlanCode: number;
  subscriptionTitle: string;
  subscriptionStatus: string;
  subscriptionStartDate: string; // ISO 8601 date format as a string
  subscriptionEndDate: string; // ISO 8601 date format as a string
  isRecurring: boolean;
  recurringAmount: number;
  recurringStartDate: string; // ISO 8601 date format as a string
  nextRecurringScheduleDate: string | null; // ISO 8601 date format as a string or null
  inTrial: boolean;
  billingCycle: string;
}

// Type for the array of subscription details
type FilteredSubscriptions = SubscriptionDetail[];

const SkeletonLoader = () => (
  <div className="animate-pulse space-y-4">
    {/* Card 1 */}
    <div className="bg-gradient-to-r from-[#1B9C83] to-emerald-400 p-6 rounded-xl flex justify-between items-center">
      <div className="space-y-2">
        <div className="bg-gray-400 h-6 w-40 rounded"></div>
        <div className="bg-gray-400 h-4 w-60 rounded"></div>
      </div>
    </div>
    {/* Divider */}
  </div>
);

const GoalSubscription = () => {
  const [isLoadingAPI, setIsLoadingAPI] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  const UpdatedLocalData = useSelector(
    (state: SessionState) => state?.session.sessionData
  );
  const [activePlans, setActivePlans] = useState([]);

  const [plans, setPlans] = useState<any[] | null>(null); // Initialize plans as null
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [subscriptionType, setSubscriptionType] = useState("Yearly");
  const [subscriptionAmount, setsubscriptionAmount] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const queryClient = useQueryClient();
  const [activePlanCodes, setActivePlanCodes] = useState<Set<any>>(new Set());
  const [getSessionCalled, setGetSessionCalled] = useState(false);
  const swiperRef = useRef<HTMLDivElement | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [createdSessionData, setcreatedSessionData] =
    useState<SessionData | null>(null);
  const [sessionID, setSessionID] = useState<string | null>(null);
  const [sessionFetched, setSessionFetched] = useState(false);
  const [showPlans, setshowPlans] = useState(false);
  const sessionData: SessionData | undefined =
    queryClient.getQueryData("sessionData");
  const updatedsessionData: SessionUpdateRequest | undefined =
    queryClient.getQueryData("updatedSessionData");
  const [activeSubscriptions, setActiveSubscriptions] = useState<any[]>([]);

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupMessage, setPopupMessage] = useState<string>("");

  const storeSesssionId = useSelector(
    (state: SessionState) => state?.session?.sessionIDStore
  );
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const showCustomToast = (
    message:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | ((props: ToastContentProps<unknown>) => React.ReactNode)
      | null
      | undefined,
    isError: boolean = false
  ) => {
    const toastType = isError ? toast.error : toast.success;

    toastType(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: isError ? "#A2252C" : "#4BB543",
        color: "white",
        fontFamily: "Poppins",
      },
      bodyClassName: "poppins-font",
    });
  };

  // const UpdatedLocalData = useSelector(
  //   (state: SessionState) => state?.session.sessionData
  // );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const slides = [
    {
      image: building,
      text: "See all your accounts in one place!",
    },
    {
      image: refresh,
      text: "Account balances sync with your bank automatically",
    },
    {
      image: contract,
      text: "Transactions are automatically sent to One Goal!",
    },
    {
      image: like,
      text: "Drag and drop to categorize transactions",
    },
    {
      image: question,
      text: "Get Priority Support",
    },
  ];

  const [billingAddresses, setBillingAddresses] = useState<BillingAddress[]>(
    []
  ); // State for billing addresses

  const storedSessionId = useSelector(
    (state: SessionState) => state?.session.sessionIDStore
  );
  const storedConsumerCode = useSelector(
    (state: SessionState) => state?.session.asaConsumerCode
  );

  const handlePaymentMethodsResponse = async (data: any) => {
    const formattedPaymentMethods: PaymentMethod[] = data.data.map(
      (method: any) => ({
        paymentMethodId: method.paymentMethodId,
        asaPaymentMethodCode: method.asaPaymentMethodCode,
        isDefault: method.isDefault,
        paymentMethodName: method.paymentMethodName,
        paymentType: method.paymentType,
        routingNumber: method.routingNumber,
        last4DigitsOfAccountNumber: method.last4DigitsOfAccountNumber,
        bankName: method.bankName,
        cardType: method.cardType,
        cardNumber: method.cardNumber,
        accountNumber: method.accountNumber,
        last4DigitsOfCardNumber: method.last4DigitsOfCardNumber,
        firstName: method.firstName,
        middleName: method.middleName,
        lastName: method.lastName,
        cardHolderName: method.cardHolderName,
        expirationYear: method.expirationYear,
        expirationMonth: method.expirationMonth,
      })
    );

    setPaymentMethods(formattedPaymentMethods);

    try {
      // Set loading state to true before making the API call

      await updateSession(
        createdSessionData,
        formattedPaymentMethods,
        "3",
        "PaymentMethodList",
        "PaymentMethodList",
        "/PaymentList",
        "INCOMPLETE",
        "INCOMPLETE"
      );
    } catch (error) {
      setIsLoading(false);

      // Handle error here
    } finally {
      // Set loading state to false after API call is completed (whether success or error)
    }
  };

  const fetchPaymentMethods = useCallback(async () => {
    try {
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL,
        POST_PAYMENT_METHOD(),
        handlePaymentMethodsResponse,
        handleApiError,
        "",
        ""
      );
    } catch (error) {
      setIsLoading(false); // Stop loading state on error
    }
  }, []);

  const handleActiveSubscription = async (response: any) => {
    setIsLoading(false);

    if (response.status === 200) {
      const activePlanCodesSet: Set<number> = new Set(
        response?.data.map((sub: any) => Number(sub.subscriptionPlanCode))
      );

      // Example usage of activePlanCodesSet
      // console.log(activePlanCodesSet); // Output: Set { 8 }
      // console.log("hjdsfdskjfhdskfhdskfds" + JSON.stringify(activePlanCodes));
      setActivePlanCodes(activePlanCodesSet);

      setActiveSubscriptions(response?.data?.activeSubscriptions || []);
      setActivePlans(
        response.data.map(
          (plan: { subscriptionPlanCode: any }) => plan.subscriptionPlanCode
        )
      );
    }

    // console.log(
    //   "ACtive SUBSCRIPTION IS AS FOLLOWS::" + JSON.stringify(response)
    // );

    // console.log("ALL Plans:", JSON.stringify(plansData, null, 2));
    // console.log("FETCH ALL SUBSCRIPTIONS:", JSON.stringify(allPlans, null, 2));
    // console.log("PREVIOUS SUBSCRIPTION:", JSON.stringify(prev, null, 2));
    // console.log("Full response data:", JSON.stringify(subscriptions, null, 2));
    // console.log("Config.asaFintechCode:", Config.asaFintechCode);
    // console.log("Type of Config.asaFintechCode:", typeof Config.asaFintechCode);

    // console.log("ndfdkfkdlfj" + JSON.stringify(subscriptions));

    //   Log each subscription's asaFintechCode
    // subscriptions.forEach((sub: { asaFintechCode: any }) =>
    //   // console.log("Subscription asaFintechCode:", sub.asaFintechCode)
    // );

    // Filter subscriptions based on whether their asaFintechCode matches config.asaFintechCode
  };

  const handleACtiveSubscriptionError = (error: any) => {
    // navigate("/error", { state: "CRB AUTHENTICATION ERROR" });
  };

  const fetchActiveSubscriptions = useCallback(async () => {
    try {
      setIsLoading(true);
      // Modify your API call to include subscriptionPlanCode if necessary
      const response = await makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_ACTIVE_SUBSCRIPTION(), // Adjust this if API requires the code
        handleActiveSubscription,
        handleACtiveSubscriptionError,
        "",
        ""
      );
    } catch (error) {
      //    console.error("Error fetching subscription plans:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSessionResponse = async (response: any) => {
    setIsLoading(false);
    if (response && response.data && response.data.asaSessionCode) {
      queryClient.setQueryData("sessionData", response);
      setcreatedSessionData(response?.data);
      showCustomToast(response?.message, false);

      // Call fetchSubscriptionPlans here after successful session response
      // await fetchSubscriptionPlans();
      await fetchAllFintech();
      await fetchActiveSubscriptions();
      await Promise.all([
        fetchSubscriptionPlans(),
        fetchBillingAddresses(),
        fetchPaymentMethods(),
      ]);
    } else if (response && response?.status == "400") {
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/error", { state: "Invalid Session" });
    } else {
      setIsLoading(false);
    }
  };

  const handleSessionError = (error: any) => {
    navigate("/error", { state: "CRB AUTHENTICATION ERROR" });
  };

  const getSession = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await makeApiCall(
        API_CALL_TYPE.GET_CALL,
        SAVE_SESSION(Config.sessionID),
        handleSessionResponse,
        handleSessionError,
        "",
        "",
        ""
      );

      return response;
    } catch (error) {
      setIsLoading(false);

      if (!getSessionCalled) {
        setGetSessionCalled(true);
        getSession();
      }
      throw error;
    }
  }, []);

  const fetchAllFintech = useCallback(async () => {
    // Define states for loading and error handling
    // const [isLoading, setIsLoading] = useState(false);
    // const [error, setError] = useState<string | null>(null);
    // const [data, setData] = useState<any>(null); // Define a more specific type if possible

    // setIsLoading(true);

    try {
      //  setIsLoadingAPI(true);
      //  setIsLoading(true);
      const response = await makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_ALL_FINTECHS(),
        handleAllFintechSuccess,
        handleAllFintechError,
        "",
        ""
      );
    } catch (error) {
      setIsLoading(false); // Always executed, regardless of success or failure
    }
  }, []);

  const fetchSubscriptionPlans = useCallback(async () => {
    try {
      setIsLoadingAPI(true);
      setIsLoading(true);
      const response = await makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_SUBSCRIPTION_PLANS(),
        handleFetchPlansSuccess,
        handleFetchPlansError,
        "",
        ""
      );
    } catch (error) {
    } finally {
      setIsLoadingAPI(false);
      setIsLoading(false);
    }
  }, []);

  const updateSession = async (
    _createdSessionDataifany: any,
    data: any,
    pageNUmber: string,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string
  ) => {
    dispatch(setLoading(true));
    setIsLoading(true);
    const updateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-ONEGOAL",
      pageCount: _createdSessionDataifany?.pageCount,
      status: "Incomplete",
      statusMessage: _createdSessionDataifany?.statusMessage,
      sessionPages: [
        {
          asaSessionCode: Config.sessionID,

          pageNumber: pageNUmber, // Page number, could be a string or a number depending on your API requirements
          pageName: pageName, // Name of the page (e.g., "Landing")
          pageTitle: pageTitle, // Title of the page (e.g., "Select Package")
          pageDescription: pageDescription, // Description of the page
          pageUrl: pageUrl, // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: pageStatusMessage, // Status message for the page
          pageData: JSON.stringify(data), // Serialize the selected plan data
        },
      ],
    };

    try {
      const sessionID: string = queryClient.getQueryData("sessionID") as string;

      if (!sessionID) {
        throw new Error("Session ID not found");
      }

      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponse,
        handleUpdateSessionError,
        "",
        "",
        updateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));

      throw error;
    }
  };
  // Handling Update Session response

  const handleUpdateSessionResponse = (response: SessionUpdateRequest) => {
    if (response && response?.status == "400") {
      // Handle the invalid session error here

      // You can show a toast message to the user or navigate to an error page
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/checkout", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases

      dispatch(setSessionData(response));
      setIsLoading(false);
      queryClient.setQueryData("updatedSessionData", response);

      // Dispatch actions, update state, etc.
    }
  };

  const updateSessiongoal = async (
    _createdSessionDataifany: any,
    data: any,
    pageNUmber: string,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string
  ) => {
    dispatch(setLoading(true));
    setIsLoading(true);
    const updateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-ONEGOAL",
      pageCount: _createdSessionDataifany?.pageCount,
      status: "Incomplete",
      statusMessage: _createdSessionDataifany?.statusMessage,
      sessionPages: [
        {
          asaSessionCode: Config.sessionID, // Use the session ID
          pageNumber: pageNUmber, // Page number, could be a string or a number depending on your API requirements
          pageName: pageName, // Name of the page (e.g., "Landing")
          pageTitle: pageTitle, // Title of the page (e.g., "Select Package")
          pageDescription: pageDescription, // Description of the page
          pageUrl: pageUrl, // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: pageStatusMessage, // Status message for the page
          pageData: JSON.stringify(data), // Serialize the selected plan data
        },
      ],
    };

    try {
      // const sessionID: string = queryClient.getQueryData("sessionID") as string;

      // if (!sessionID) {
      //   throw new Error("Session ID not found");
      // }

      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponsegoal,
        handleUpdateSessionError,
        "",
        "",
        updateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));

      throw error;
    }
  };
  // Handling Update Session response

  const handleUpdateSessionResponsegoal = (response: SessionUpdateRequest) => {
    if (response && response?.status == "400") {
      // Handle the invalid session error here

      // You can show a toast message to the user or navigate to an error page
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/checkout", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases

      dispatch(setSessionData(response));
      setIsLoading(false);
      queryClient.setQueryData("updatedSessionData", response);

      //  navigate("/ListPay");
      navigate("/portfoliopilot");

      // Dispatch actions, update state, etc.
    }
  };
  const handleUpdateSessionResponse1 = (response: SessionUpdateRequest) => {
    if (response && response?.status == "400") {
      // Handle the invalid session error here

      // You can show a toast message to the user or navigate to an error page
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/checkout", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases

      dispatch(setSessionData(response));
      setIsLoading(false);
      queryClient.setQueryData("updatedSessionData", response);

      // Dispatch actions, update state, etc.
    }
  };

  // Handling Update Session Error

  const handleUpdateSessionError = (_error: any) => {
    setIsLoading(false);
    dispatch(setLoading(false));
  };

  const handleFetchPlansSuccess = async (data: any) => {
    if (data && data.data) {
      setPlans(data.data);

      await updateSession(
        createdSessionData,
        data.data,
        "1",
        "GOALS PLANS",
        "USER SELECTED PLAN",
        "/oneGoal",
        "INCOMPLETE",
        "INCOMPLETE"
      );
    } else {
      setPlans([]);
    }
  };

  const handleFetchPlansError = (error: any) => {};

  const handleAllFintechSuccess = async (data: any) => {
    // console.log("ALL FINTECHS ..." + JSON.stringify(data));

    const filtered_data = data?.data?.filter(
      (item: any) => item["asaFintechCode"] === Config.asaFicode
    );

    // Extract fintechName from filtered records
    const fintechNames = filtered_data.map((item: any) => item["fintechName"]);
    //
    // console.log("Filtered Fintech Names: " + JSON.stringify(fintechNames));

    // console.log(
    //   "Filtered Fintech record is here:: " + JSON.stringify(filtered_data)
    // );

    const updateSessionfintech = async (
      _createdSessionDataifany: any,
      data: any,
      pageNUmber: string,
      pageName: string,
      pageTitle: string,
      pageDescription: string,
      pageUrl: string,
      pageStatusMessage: string
    ) => {
      dispatch(setLoading(true));
      setIsLoading(true);
      const updateRequest: SessionUpdateRequest = {
        asaSessionCode: Config.sessionID,
        asaConsumerCode: Config.asaConsumerCode,
        version: Config.X_ASA_version.toString(),
        sessionName: "ASAVAULT-ONEGOAL",
        pageCount: _createdSessionDataifany?.pageCount,
        status: "Incomplete",
        statusMessage: _createdSessionDataifany?.statusMessage,
        sessionPages: [
          {
            asaSessionCode: Config.sessionID,

            pageNumber: pageNUmber, // Page number, could be a string or a number depending on your API requirements
            pageName: pageName, // Name of the page (e.g., "Landing")
            pageTitle: pageTitle, // Title of the page (e.g., "Select Package")
            pageDescription: pageDescription, // Description of the page
            pageUrl: pageUrl, // URL of the page
            pageStatus: "Complete", // Status of the page (e.g., "New")
            pageStatusMessage: pageStatusMessage, // Status message for the page
            pageData: JSON.stringify(data), // Serialize the selected plan data
          },
        ],
      };

      try {
        const sessionID: string = queryClient.getQueryData(
          "sessionID"
        ) as string;

        if (!sessionID) {
          throw new Error("Session ID not found");
        }

        const response = await makeApiCall(
          API_CALL_TYPE.PUT_CALL,
          UPDATE_SESSION(),
          handleUpdateSessionResponse,
          handleUpdateSessionError,
          "",
          "",
          updateRequest
        );

        return response;
      } catch (error) {
        setIsLoading(false);
        dispatch(setError("Error updating session:"));

        throw error;
      }
    };

    await updateSessionfintech(
      createdSessionData,
      { Landing: "true" }, // Correctly formatted object
      "81",
      "Landingyes",
      "Landingyes",
      "/Landingyes",
      "/onegoal",
      "INCOMPLETE"
    );

    await updateSession(
      createdSessionData,
      filtered_data,
      "91",
      "FintechName",
      "FintechName",
      "/FintechName",
      "INCOMPLETE",
      "INCOMPLETE"
    );

    // if (data && data.data) {
    //   setPlans(data.data);

    //   await updateSession(
    //     createdSessionData,
    //     data.data,
    //     "1",
    //     "GOALS PLANS",
    //     "USER SELECTED PLAN",
    //     "/oneGoal",
    //     "INCOMPLETE",
    //     "INCOMPLETE"
    //   );
    // } else {
    //   setPlans([]);
    // }
  };

  const handleAllFintechError = (error: any) => {};

  const handleBackClick = () => {
    if (UpdatedLocalData) {
      const sessionPages = UpdatedLocalData.data.sessionPages;
      let backActionDynamicLink = null;

      for (let i = 0; i < sessionPages.length; i++) {
        if (sessionPages[i].pageNumber === 0) {
          const pageData = JSON.parse(sessionPages[i].pageData);
          backActionDynamicLink = pageData.backActionDynamicLink;
          break;
        }
      }

      if (backActionDynamicLink) {
        window.location.href = backActionDynamicLink;
      } else {
        console.error("Back action dynamic link not found.");
      }
    } else {
      console.error("UpdatedLocalData is not available.");
    }
  };

  const updateSession1 = async (
    updatedSessionData: SessionUpdateRequest,
    session_if_any: any,
    pageNumber: string,
    pageName: string,

    pageTitle: string
  ) => {
    dispatch(setLoading(true));
    //setIsLoading(true);

    const sessionUpdateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,

      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-GOAL",
      pageCount: session_if_any.pageCount,
      status: "Incomplete", // Ensure the status field is a string,
      statusMessage: updatedSessionData.statusMessage,
      sessionPages: [
        ...(Array.isArray(updatedSessionData.sessionPages)
          ? updatedSessionData.sessionPages
          : []),
        {
          asaSessionCode: Config.sessionID,
          pageNumber: pageNumber, // Assuming this is the second page
          pageName: pageName,
          pageTitle: pageTitle,
          pageDescription: pageTitle,
          pageUrl: "/PaymentList",
          pageStatus: "Completed",
          pageStatusMessage: "PaymentList",
          pageData: JSON.stringify(session_if_any), // Serialize the selected plan data
        },
      ],
    };

    try {
      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponse1,
        handleUpdateSessionError,
        "",
        "",
        sessionUpdateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));
      throw error;
    }
  };

  const handleBillingAddressesResponse = async (data: any) => {
    const formattedBillingAddresses: BillingAddress[] = data?.data
      .filter((address: any) => address.isBilling === true)
      .map((address: any) => ({
        consumerAddressId: address.consumerAddressId,
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
        firstName: address.firstName,
        lastName: address.lastName,
        country: address.country,
      }));

    setBillingAddresses(formattedBillingAddresses);

    try {
      let dataToUpdate; // Declare variable to hold the data to be updated

      // Check if UpdatedLocalData contains data and use it if available
      if (UpdatedLocalData) {
        dataToUpdate = UpdatedLocalData;
      } else {
        dataToUpdate = updatedsessionData;
      }

      if (dataToUpdate) {
        await updateSession(
          createdSessionData,
          formattedBillingAddresses,
          "7",
          "BillAddressList",
          "BillAddressList",
          "/oneGoal",
          "INCOMPLETE",
          "INCOMPLETE"
        );
        // Navigate to payment screen after successful API call
        // navigate("/payment");
      } else {
        console.error("No session data available to update.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating session:", error);
      // Handle error here
    } finally {
      // Set loading state to false after API call is completed (whether success or error)
    }

    setIsLoading(false); // Stop loading state
  };

  const handleApiError = (error: any) => {
    setIsLoading(false); // Stop loading state on error
  };

  const fetchBillingAddresses = useCallback(async () => {
    try {
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_BILLING_ADDRESSES(),
        handleBillingAddressesResponse,
        handleApiError,
        "",
        ""
      );
    } catch (error) {
      setIsLoading(false); // Stop loading state on error
    }
  }, []);

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    // const currentUrl = new URL(Config.BROWSER_URL);
    const sessionID = currentUrl.searchParams.get("sessionid");
    const asaConsumerCode = currentUrl.searchParams.get("asaConsumerCode");
    const versionCode = currentUrl.searchParams.get("version");
    Config.X_ASA_version = versionCode ? parseFloat(versionCode) : 1.08; // const asaFiCode = currentUrl.searchParams.get("asaFiCode");
    const token = currentUrl.searchParams.get("token");
    const asaFicode = currentUrl.searchParams.get("asaFintechCode");
    // const asaFintechCode = currentUrl.searchParams.get("asaFintechCode");

    if (asaFicode !== null) {
      const parsedFicode = parseInt(asaFicode, 10);
      if (!isNaN(parsedFicode)) {
        Config.asaFicode = parsedFicode;
      } else {
        // Handle case where asaFicode is not a valid number
        console.error("Invalid asaFicode:", asaFicode);
      }
    }
    let decodedLink: string | null = null;
    const encodedLink = currentUrl.searchParams.get("dynamicLink");
    if (encodedLink !== null) {
      decodedLink = decodeURIComponent(encodedLink); // Assign decodedLink inside the if block
      dispatch(setDynamicLink(decodedLink)); // Dispatch action to save dynamic link
      queryClient.setQueryData("dynamicLink", decodedLink);
    } else {
      console.error("encodedLink is nulldsfdsfdsf");
    } // Declare decodedLink outside the if block
    if (sessionID && asaConsumerCode) {
      // Update Config object with session ID and subscription key
      Config.sessionID = sessionID;
      Config.asaConsumerCode = parseInt(asaConsumerCode);
      // Save session ID to React Query cache
      queryClient.setQueryData("sessionID", sessionID);
      setSessionID(sessionID);
      dispatch(setSessionIDStore(sessionID));
      dispatch(setAsaConsumerCode(Config.asaConsumerCode));

      // if (sessionID) {
      // If sessionID is not set, call getSession to fetch it
      setSessionFetched(true);
      getSession();

      // }
    } else {
      if (UpdatedLocalData) {
        Config.sessionID = UpdatedLocalData?.data?.asaSessionCode;
        Config.asaConsumerCode = parseInt(
          UpdatedLocalData?.data?.asaConsumerCode
        );

        queryClient.setQueryData("sessionID", Config.sessionID);
        setSessionID(Config.sessionID);
        dispatch(setSessionIDStore(UpdatedLocalData?.data?.asaSessionCode));
        dispatch(setAsaConsumerCode(Config.asaConsumerCode));

        const fintechPage = UpdatedLocalData?.sessionPages?.find(
          (page: { pageName: string }) => page.pageName === "FintechName"
        );

        if (fintechPage) {
          const pageData = JSON.parse(fintechPage.pageData);
          if (Array.isArray(pageData) && pageData.length > 0) {
            Config.asaFintechCode = pageData[0].asaFintechCode; // Assuming you want the first one
          }
        }

        // if (sessionID) {
        // If sessionID is not set, call getSession to fetch it
        setSessionFetched(true);
        getSession();
      }

      fetchAllFintech();

      //navigate("/error", { state: "CRB AUTHENTICATION ERROR" });
    }
    // }, [sessionID, Config.asaConsumerCode, sessionFetched]); // Run whenever sessionID changes
  }, [showPlans]); // Run whenever sessionID changes

  const handlePlanClick = async (plan: any) => {
    setSubscriptionType(plan?.billingCycle);
    setsubscriptionAmount(plan?.oneTimeAmount);
    queryClient.setQueryData("selectedPlan", plan);
    setshowPlans(true);

    if (activePlanCodes.has(plan.subscriptionPlanCode)) {
      setPopupMessage(`This Subscription plan is already Active`);
      setShowPopup(true);
    } else {
      try {
        await updateSessiongoal(
          createdSessionData,
          plan,
          "2",
          "GOALS PLANS",
          "Select Package",
          "Select Package",
          "/onegoal",
          "INCOMPLETE"
        );
      } catch (error) {
        setIsLoading(false);
        dispatch(setError("Error fetching session:"));
        console.error("Error fetching session:", error);
        throw error;
      }
    }

    // Navigate to the next page or perform other actions
    // navigate("/ListPayments", { state: { plan } });
  };

  const clickSubscription = () => {
    setIsLoading(true);

    //  console.log("Updated Local data ::: " + UpdatedLocalData);
    setTimeout(() => {
      setIsLoading(false);
    }, 50000); // 5000 milliseconds = 5 seconds

    // Logic to load your plans
    setshowPlans(true);
    // Simulate fetching plans
    // setPlans(fetchedPlans);
    // setIsLoading(false); // After fetching, set loading to false
  };

  return (
    <div className="bg-gray-900 flex justify-center items-center min-h-screen">
      {isLoading && <LoaderComponent />}{" "}
      <div className="bg-gradient-to-r from-[#1B9C83] to-[#20BD9D] w-full h-screen overflow-y-auto text-white relative z-20">
        <div className="flex items-center justify-evenly space-x-8 pt-8 p-3">
          <div
            className="rounded-[10px] p-1 w-[70px] h-[45px] flex items-center justify-center"
            style={{
              backgroundColor: "#0000001A", // 10% black
              boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
            }}
          >
            <img
              className="cursor-pointer w-[20px] h-[20px]"
              src={arrowBack}
              onClick={handleBackClick}
              alt="Back"
            />
          </div>

          <h1 className="font-poppins ml-3  text-[22px] leading-[28px] tracking-[1px] w-[500px] h-[56px] mt-6 ">
            Love & Money, Finally
            <br /> in Sync.
          </h1>

          {/* <img className="h-16 w-16 mx-6" src={pwa_image} alt="PWA" /> */}
        </div>

        <img
          src={flash}
          className="relative top-2 left-5 z-50 w-[65px] h-[65px]"
          alt="Flash"
        />

        <div className="bg-[#121212] top-8 rounded-more text-gray-800 mx-auto rounded-t-[3rem] absolute bottom-0 left-0 right-0 h-full overflow-y-auto mt-24">
          <div className="h-full p-6 shadow-lg">
            <div className="flex items-center mb-2">
              <img
                src={flash}
                className="relative -top-2 hidden left-5 z-50 w-[80px] h-[80px]"
                alt="Flash"
              />
              <span className="font-semibold relative -top-4 font-poppins left-20 text-[18px] text-white">
                One Goal
              </span>
            </div>

            <div className="border-[3.91px] border-[#1B9C83] rounded-xl">
              <div className="rounded-xl bg-[#c9eae5] p">
                {/* <button
                  style={{ letterSpacing: "2px" }}
                  className="bg-[#96d9cd] border-[#1B9C83] border text-black relative top-3 left-5 px-3 py-0 rounded-full border-b-4 font-poppins text-[10px] font-bold mb-4"
                >
                  SUBSCRIBE
                </button> */}

                {/* 
                <button className="bg-[#96d9cd] border-[#1B9C83]  relative top-5 left-5 px-3 py-1 tracking-widest rounded-full border-b-4 text-sm font-bold mb-6">
                  SUBSCRIBE
                </button> */}

                <button
                  className="bg-[#39a68f] border-[#1B9C83] border text-black relative top-2 left-3 px-2 py-1 tracking-widest rounded-full border-b-4 text-xs mb-6"
                  style={{
                    fontSize: "12px", // Adjust the font size to make the text smaller
                    fontWeight: 600,
                    letterSpacing: "0.15em",
                    textAlign: "center",
                  }}
                >
                  SUBSCRIBE
                </button>

                <ImageTextSlider slides={slides} />
              </div>
              <div className="bg-white">
                <div className="space-y-3 mt-6 p-2 bg-white rounded-xl">
                  {!showPlans ? (
                    <button
                      onClick={clickSubscription}
                      className="w-full bg-[#39a68f] text-white py-5 rounded-lg shadow-md font-poppins font-semibold flex justify-between items-center"
                      style={{
                        boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      <span className="ml-4 font-poppins">Continue</span>{" "}
                      {/* Left margin for Continue text */}
                      <img
                        src={forward_arrow}
                        alt="Forward Arrow"
                        className="w-2 h-4 mr-4"
                      />{" "}
                      {/* Right margin for arrow */}
                    </button>
                  ) : (
                    <>
                      {plans && plans.length > 0 ? ( // Check if plans is not null and has items
                        plans.map((plan) => (
                          <button
                            key={plan.subscriptionPlanCode}
                            onClick={() => handlePlanClick(plan)} // Ensure handlePlanClick is defined
                            className={`w-full text-white p-4 rounded-xl text-left flex justify-between items-center ${
                              activePlanCodes.has(plan.subscriptionPlanCode)
                                ? "bg-gradient-to-r from-[#1B9C83] to-[#3aa690]"
                                : "bg-gradient-to-r from-[#1B9C83] to-[#3aa690]"
                            }`}
                          >
                            <div>
                              <div
                                className="font-poppins"
                                style={{
                                  fontWeight: 600,
                                  fontSize: "16px",
                                  lineHeight: "20px",
                                  letterSpacing: "-0.1px",
                                }}
                              >
                                {plan.billingCycle === "Monthly"
                                  ? `$${plan.recurringAmount} / month`
                                  : `$${plan.recurringAmount} / year`}
                              </div>
                              <div
                                className="font-medium font-poppins"
                                style={{
                                  fontSize: "12px",
                                  lineHeight: "20px",
                                  letterSpacing: "-0.1px",
                                }}
                              >
                                Billed{" "}
                                {plan.billingCycle === "Monthly"
                                  ? plan.recurringAmount
                                  : plan.oneTimeAmount}{" "}
                                every{" "}
                                {plan.billingCycle.toLowerCase() === "annually"
                                  ? "year"
                                  : "month"}
                                , starting today
                              </div>
                            </div>
                            <div className="w-2 h-2">
                              <img src={forward_arrow} alt="Forward Arrow" />
                            </div>
                          </button>
                        ))
                      ) : (
                        <p className="no-plans-message font-poppins">
                          No subscription plans available
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="bg-[#121212] mt-6 px-6">
              <p
                className="continiuep text-left font text-white text-[13px] font-medium"
                style={{ letterSpacing: "-0.45px" }}
              >
                By continuing, you are indicating that you accept our{" "}
                <a
                  href="https://onegoalfinance.com/terms"
                  target="_blank"
                  className="underline font-poppins font-semibold text-[13px]"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://onegoalfinance.com/privacy-policy"
                  target="_blank"
                  className="underline font-poppins font-semibold text-[13px]"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>

            {/* Enhanced Popup Modal */}
            {showPopup && (
              <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ease-in-out">
                <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm mx-4 sm:mx-8 lg:mx-12">
                  <div className="flex flex-col items-center">
                    <div className="text-center mb-4">
                      <p className="font-poppins text-xl font-poppins text-gray-800">
                        {popupMessage}
                      </p>
                    </div>
                    <button
                      className="bg-[#1B9C83] text-white px-4 py-2 rounded-md font-semibold hover:bg-[#169b6b] transition-colors duration-200"
                      onClick={() => setShowPopup(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalSubscription;
