import React, { useEffect, useState, useCallback, useRef } from "react";
import arrowBack from "../../assets/Images/arrow_back_ios_new.png";
import money_patrol_ui from "../../assets/Images/money_patrol_logo.svg";
import circle_right_arrow from "../../assets/Images/circle_right_arrow.svg";
import back_arrow_money from "../../assets/Images/back_arroe_money.svg";
import { makeApiCall } from "../../services/ApiService";
import {
  API_CALL_TYPE,
  GET_ACTIVE_SUBSCRIPTION,
  GET_ALL_FINTECHS,
  GET_BILLING_ADDRESSES,
  GET_FOREVER_BILLING,
  GET_SUBSCRIPTION_PLANS,
  POST_PAYMENT_METHOD,
  SAVE_SESSION,
  UPDATE_SESSION,
} from "../../services/Api";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setSessionData,
  setLoading,
  setError,
  setDynamicLink,
  SessionState,
  setSessionIDStore,
  setAsaConsumerCode,
} from "../../redux/sessionSlice";
import {
  BillingAddress,
  PaymentMethod,
  SessionData,
  SessionUpdateRequest,
} from "../../types/types";
import { ToastContentProps, toast } from "react-toastify";
import { Config } from "../../Utils/AppConstants";
import LoaderComponent from "../../components/LoaderComponent";
import "./MoneyPatrol.css"; // Import your CSS file for animations

const MoneyPatrolLanding = () => {
  const [isLoadingAPI, setIsLoadingAPI] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  const UpdatedLocalData = useSelector(
    (state: SessionState) => state?.session.sessionData
  );
  const [activePlans, setActivePlans] = useState([]);

  const [plans, setPlans] = useState<any[]>([]); // Initialize plans as an empty array

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [subscriptionType, setSubscriptionType] = useState("Yearly");
  const [subscriptionAmount, setsubscriptionAmount] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const queryClient = useQueryClient();
  const [activePlanCodes, setActivePlanCodes] = useState<Set<any>>(new Set());
  const [getSessionCalled, setGetSessionCalled] = useState(false);
  const swiperRef = useRef<HTMLDivElement | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [createdSessionData, setcreatedSessionData] =
    useState<SessionData | null>(null);
  const [sessionID, setSessionID] = useState<string | null>(null);
  const [sessionFetched, setSessionFetched] = useState(false);
  const [showPlans, setshowPlans] = useState(false);
  const sessionData: SessionData | undefined =
    queryClient.getQueryData("sessionData");
  const updatedsessionData: SessionUpdateRequest | undefined =
    queryClient.getQueryData("updatedSessionData");
  const [activeSubscriptions, setActiveSubscriptions] = useState<any[]>([]);

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupMessage, setPopupMessage] = useState<string>("");
  const [selectedPlan, setSelectedPlan] = useState<{
    subscriptionTitle: string;
    description: string;
    oneTimeAmount: any;
    plan: any;
  } | null>(null);
  const storeSesssionId = useSelector(
    (state: SessionState) => state?.session?.sessionIDStore
  );
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const showCustomToast = (
    message:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | ((props: ToastContentProps<unknown>) => React.ReactNode)
      | null
      | undefined,
    isError: boolean = false
  ) => {
    const toastType = isError ? toast.error : toast.success;

    toastType(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: isError ? "#A2252C" : "#4BB543",
        color: "white",
        fontFamily: "Poppins",
      },
      bodyClassName: "poppins-font",
    });
  };

  // const UpdatedLocalData = useSelector(
  //   (state: SessionState) => state?.session.sessionData
  // );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [billingAddresses, setBillingAddresses] = useState<BillingAddress[]>(
    []
  ); // State for billing addresses

  const storedSessionId = useSelector(
    (state: SessionState) => state?.session.sessionIDStore
  );
  const storedConsumerCode = useSelector(
    (state: SessionState) => state?.session.asaConsumerCode
  );

  const handlePaymentMethodsResponse = async (data: any) => {
    const formattedPaymentMethods: PaymentMethod[] = data.data.map(
      (method: any) => ({
        paymentMethodId: method.paymentMethodId,
        asaPaymentMethodCode: method.asaPaymentMethodCode,
        isDefault: method.isDefault,
        paymentMethodName: method.paymentMethodName,
        paymentType: method.paymentType,
        routingNumber: method.routingNumber,
        last4DigitsOfAccountNumber: method.last4DigitsOfAccountNumber,
        bankName: method.bankName,
        cardType: method.cardType,
        cardNumber: method.cardNumber,
        accountNumber: method.accountNumber,
        last4DigitsOfCardNumber: method.last4DigitsOfCardNumber,
        firstName: method.firstName,
        middleName: method.middleName,
        lastName: method.lastName,
        cardHolderName: method.cardHolderName,
        expirationYear: method.expirationYear,
        expirationMonth: method.expirationMonth,
      })
    );

    setPaymentMethods(formattedPaymentMethods);
    try {
      // Set loading state to true before making the API call
      await updateSession(
        createdSessionData,
        formattedPaymentMethods,
        "3",
        "PaymentMethodList",
        "PaymentMethodList",
        "/PaymentList",
        "INCOMPLETE",
        "INCOMPLETE"
      );
    } catch (error) {
      setIsLoading(false);
      // Handle error here
    } finally {
      // Set loading state to false after API call is completed (whether success or error)
    }
  };

  const fetchPaymentMethods = useCallback(async () => {
    try {
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL,
        POST_PAYMENT_METHOD(),
        handlePaymentMethodsResponse,
        handleApiError,
        "",
        ""
      );
    } catch (error) {
      setIsLoading(false); // Stop loading state on error
    }
  }, []);

  const handleActiveSubscription = async (response: any) => {
    setIsLoading(false);

    if (response.status === 200) {
      const activePlanCodesSet: Set<number> = new Set(
        response?.data.map((sub: any) => Number(sub.subscriptionPlanCode))
      );
      setActivePlanCodes(activePlanCodesSet);
      setActiveSubscriptions(response?.data || []);
      setActivePlans(
        response.data.map(
          (plan: { subscriptionPlanCode: any }) => plan.subscriptionPlanCode
        )
      );
    }
  };

  const handleACtiveSubscriptionError = (error: any) => {
    // navigate("/error", { state: "CRB AUTHENTICATION ERROR" });
  };

  const fetchActiveSubscriptions = useCallback(async () => {
    try {
      setIsLoading(true);
      // Modify your API call to include subscriptionPlanCode if necessary
      const response = await makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_ACTIVE_SUBSCRIPTION(), // Adjust this if API requires the code
        handleActiveSubscription,
        handleACtiveSubscriptionError,
        "",
        ""
      );
    } catch (error) {
      //    console.error("Error fetching subscription plans:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSessionResponse = async (response: any) => {
    setIsLoading(false);
    if (response && response.data && response.data.asaSessionCode) {
      queryClient.setQueryData("sessionData", response);
      setcreatedSessionData(response?.data);
      showCustomToast(response?.message, false);
      // Call fetchSubscriptionPlans here after successful session response
      await fetchAllFintech();
      await fetchActiveSubscriptions();
      await Promise.all([
        fetchSubscriptionPlans(),
        fetchBillingAddresses(),
        fetchPaymentMethods(),
      ]);
    } else if (response && response?.status == "400") {
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/error", { state: "Invalid Session" });
    } else {
      setIsLoading(false);
    }
  };

  const handleSessionError = (error: any) => {
    navigate("/error", { state: "CRB AUTHENTICATION ERROR" });
  };

  const getSession = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await makeApiCall(
        API_CALL_TYPE.GET_CALL,
        SAVE_SESSION(Config.sessionID),
        handleSessionResponse,
        handleSessionError,
        "",
        "",
        ""
      );

      return response;
    } catch (error) {
      setIsLoading(false);

      if (!getSessionCalled) {
        setGetSessionCalled(true);
        getSession();
      }
      throw error;
    }
  }, []);

  const fetchAllFintech = useCallback(async () => {
    try {
      const response = await makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_ALL_FINTECHS(),
        handleAllFintechSuccess,
        handleAllFintechError,
        "",
        ""
      );
    } catch (error) {
      setIsLoading(false); // Always executed, regardless of success or failure
    }
  }, []);

  const fetchSubscriptionPlans = useCallback(async () => {
    try {
      setIsLoadingAPI(true);
      setIsLoading(true);
      const response = await makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_SUBSCRIPTION_PLANS(),
        handleFetchPlansSuccess,
        handleFetchPlansError,
        "",
        ""
      );
    } catch (error) {
    } finally {
      setIsLoadingAPI(false);
      setIsLoading(false);
    }
  }, []);

  const updateSession = async (
    _createdSessionDataifany: any,
    data: any,
    pageNUmber: string,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string
  ) => {
    dispatch(setLoading(true));
    setIsLoading(true);
    const updateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT",
      pageCount: _createdSessionDataifany?.pageCount,
      status: "Incomplete",
      statusMessage: _createdSessionDataifany?.statusMessage,
      sessionPages: [
        {
          asaSessionCode: Config.sessionID,
          pageNumber: pageNUmber, // Page number, could be a string or a number depending on your API requirements
          pageName: pageName, // Name of the page (e.g., "Landing")
          pageTitle: pageTitle, // Title of the page (e.g., "Select Package")
          pageDescription: pageDescription, // Description of the page
          pageUrl: pageUrl, // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: pageStatusMessage, // Status message for the page
          pageData: JSON.stringify(data), // Serialize the selected plan data
        },
      ],
    };

    try {
      const sessionID: string = queryClient.getQueryData("sessionID") as string;

      if (!sessionID) {
        throw new Error("Session ID not found");
      }
      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponse,
        handleUpdateSessionError,
        "",
        "",
        updateRequest
      );
      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));

      throw error;
    }
  };
  // Handling Update Session response

  const handleUpdateSessionResponse = (response: SessionUpdateRequest) => {
    if (response && response?.status == "400") {
      // Handle the invalid session error here
      // You can show a toast message to the user or navigate to an error page
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/checkout", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases
      dispatch(setSessionData(response));
      setIsLoading(false);
      queryClient.setQueryData("updatedSessionData", response);
      // Dispatch actions, update state, etc.
    }
  };

  const updateSessiongoal = async (
    _createdSessionDataifany: any,
    data: any,
    pageNUmber: string,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string
  ) => {
    dispatch(setLoading(true));
    setIsLoading(true);
    const updateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT",
      pageCount: _createdSessionDataifany?.pageCount,
      status: "Incomplete",
      statusMessage: _createdSessionDataifany?.statusMessage,
      sessionPages: [
        {
          asaSessionCode: Config.sessionID, // Use the session ID
          pageNumber: pageNUmber, // Page number, could be a string or a number depending on your API requirements
          pageName: pageName, // Name of the page (e.g., "Landing")
          pageTitle: pageTitle, // Title of the page (e.g., "Select Package")
          pageDescription: pageDescription, // Description of the page
          pageUrl: pageUrl, // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: pageStatusMessage, // Status message for the page
          pageData: JSON.stringify(data), // Serialize the selected plan data
        },
      ],
    };

    try {
      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponsegoal,
        handleUpdateSessionError,
        "",
        "",
        updateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));
      throw error;
    }
  };

  const updateSessionBilling = async (
    _createdSessionDataifany: any,
    data: any,
    pageNUmber: string,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string
  ) => {
    dispatch(setLoading(true));
    setIsLoading(true);
    const updateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT",
      pageCount: _createdSessionDataifany?.pageCount,
      status: "Incomplete",
      statusMessage: _createdSessionDataifany?.statusMessage,
      sessionPages: [
        {
          asaSessionCode: Config.sessionID, // Use the session ID
          pageNumber: pageNUmber, // Page number, could be a string or a number depending on your API requirements
          pageName: pageName, // Name of the page (e.g., "Landing")
          pageTitle: pageTitle, // Title of the page (e.g., "Select Package")
          pageDescription: pageDescription, // Description of the page
          pageUrl: pageUrl, // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: pageStatusMessage, // Status message for the page
          pageData: JSON.stringify(data), // Serialize the selected plan data
        },
      ],
    };

    try {
      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponseBilling,
        handleUpdateSessionError,
        "",
        "",
        updateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));

      throw error;
    }
  };
  // Handling Update Session response

  const handleUpdateSessionResponsegoal = (response: SessionUpdateRequest) => {
    if (response && response?.status == "400") {
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/checkout", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases
      dispatch(setSessionData(response));
      setIsLoading(false);
      queryClient.setQueryData("updatedSessionData", response);
    }
  };

  const handleUpdateSessionResponseBilling = (
    response: SessionUpdateRequest
  ) => {
    if (response && response?.status == "400") {
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/checkout", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases
      dispatch(setSessionData(response));
      setIsLoading(false);
      queryClient.setQueryData("updatedSessionData", response);
      navigate("/portfoliopilot");
    }
  };
  const handleUpdateSessionResponse1 = (response: SessionUpdateRequest) => {
    if (response && response?.status == "400") {
      // Handle the invalid session error here

      // You can show a toast message to the user or navigate to an error page
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/checkout", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases
      dispatch(setSessionData(response));
      setIsLoading(false);
      queryClient.setQueryData("updatedSessionData", response);
      // Dispatch actions, update state, etc.
    }
  };

  // Handling Update Session Error

  const handleUpdateSessionError = (_error: any) => {
    setIsLoading(false);
    dispatch(setLoading(false));
  };

  const handleFetchPlansSuccess = async (data: any) => {
    if (data && data.data) {
      setPlans(data.data);
      await updateSession(
        createdSessionData,
        data.data,
        "1",
        "GOALS PLANS",
        "USER SELECTED PLAN",
        "/oneGoal",
        "INCOMPLETE",
        "INCOMPLETE"
      );
    } else {
      setPlans([]);
    }
  };

  const handleFetchPlansError = (error: any) => {};
  const updateSessionfintech = async (
    _createdSessionDataifany: any,
    data: any,
    pageNUmber: string,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string
  ) => {
    dispatch(setLoading(true));
    setIsLoading(true);
    const updateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT",
      pageCount: _createdSessionDataifany?.pageCount,
      status: "Incomplete",
      statusMessage: _createdSessionDataifany?.statusMessage,
      sessionPages: [
        {
          asaSessionCode: Config.sessionID,
          pageNumber: pageNUmber, // Page number, could be a string or a number depending on your API requirements
          pageName: pageName, // Name of the page (e.g., "Landing")
          pageTitle: pageTitle, // Title of the page (e.g., "Select Package")
          pageDescription: pageDescription, // Description of the page
          pageUrl: pageUrl, // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: pageStatusMessage, // Status message for the page
          pageData: JSON.stringify(data), // Serialize the selected plan data
        },
      ],
    };

    try {
      const sessionID: string = queryClient.getQueryData("sessionID") as string;

      if (!sessionID) {
        throw new Error("Session ID not found");
      }
      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponse,
        handleUpdateSessionError,
        "",
        "",
        updateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));

      throw error;
    }
  };

  const handleAllFintechSuccess = async (data: any) => {
    // console.log("ALL FINTECHS ..." + JSON.stringify(data));

    const filtered_data = data?.data?.filter(
      (item: any) => item["asaFintechCode"] === Config.asaFicode
    );

    // Extract fintechName from filtered records
    const fintechNames = filtered_data.map((item: any) => item["fintechName"]);
    await updateSessionfintech(
      createdSessionData,
      { Landing: "true" }, // Correctly formatted object
      "81",
      "Landingyes",
      "Landingyes",
      "/moneypatrol",
      "/moneypatrol",
      "INCOMPLETE"
    );

    await updateSession(
      createdSessionData,
      filtered_data,
      "91",
      "FintechName",
      "FintechName",
      "/FintechName",
      "INCOMPLETE",
      "INCOMPLETE"
    );
  };

  const handleAllFintechError = (error: any) => {};

  const onBackButtonClick = () => {
    if (UpdatedLocalData) {
      const sessionPages = UpdatedLocalData.data.sessionPages;
      let backActionDynamicLink = null;

      for (let i = 0; i < sessionPages.length; i++) {
        if (sessionPages[i].pageNumber === 0) {
          const pageData = JSON.parse(sessionPages[i].pageData);
          backActionDynamicLink = pageData.backActionDynamicLink;
          break;
        }
      }

      if (backActionDynamicLink) {
        window.location.href = backActionDynamicLink;
      } else {
        console.error("Back action dynamic link not found.");
      }
    } else {
      console.error("UpdatedLocalData is not available.");
    }
  };

  const onBackPlanSelect = () => {
    setSelectedPlan(null);
  };

  const updateSession1 = async (
    updatedSessionData: SessionUpdateRequest,
    session_if_any: any,
    pageNumber: string,
    pageName: string,

    pageTitle: string
  ) => {
    dispatch(setLoading(true));
    //setIsLoading(true);

    const sessionUpdateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,

      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT",
      pageCount: session_if_any.pageCount,
      status: "Incomplete", // Ensure the status field is a string,
      statusMessage: updatedSessionData.statusMessage,
      sessionPages: [
        ...(Array.isArray(updatedSessionData.sessionPages)
          ? updatedSessionData.sessionPages
          : []),
        {
          asaSessionCode: Config.sessionID,
          pageNumber: pageNumber, // Assuming this is the second page
          pageName: pageName,
          pageTitle: pageTitle,
          pageDescription: pageTitle,
          pageUrl: "/PaymentList",
          pageStatus: "Completed",
          pageStatusMessage: "PaymentList",
          pageData: JSON.stringify(session_if_any), // Serialize the selected plan data
        },
      ],
    };

    try {
      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponse1,
        handleUpdateSessionError,
        "",
        "",
        sessionUpdateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));
      throw error;
    }
  };

  const handleBillingAddressesResponse = async (data: any) => {
    const formattedBillingAddresses: BillingAddress[] = data?.data
      .filter((address: any) => address.isBilling === true)
      .map((address: any) => ({
        consumerAddressId: address.consumerAddressId,
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
        firstName: address.firstName,
        lastName: address.lastName,
        country: address.country,
      }));

    setBillingAddresses(formattedBillingAddresses);

    try {
      let dataToUpdate; // Declare variable to hold the data to be updated

      // Check if UpdatedLocalData contains data and use it if available
      if (UpdatedLocalData) {
        dataToUpdate = UpdatedLocalData;
      } else {
        dataToUpdate = updatedsessionData;
      }

      if (dataToUpdate) {
        await updateSession(
          createdSessionData,
          formattedBillingAddresses,
          "7",
          "BillAddressList",
          "BillAddressList",
          "/oneGoal",
          "INCOMPLETE",
          "INCOMPLETE"
        );
        // Navigate to payment screen after successful API call
        // navigate("/payment");
      } else {
        console.error("No session data available to update.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating session:", error);
      // Handle error here
    } finally {
      // Set loading state to false after API call is completed (whether success or error)
    }

    setIsLoading(false); // Stop loading state
  };

  const handleApiError = (error: any) => {
    setIsLoading(false); // Stop loading state on error
  };

  const fetchBillingAddresses = useCallback(async () => {
    try {
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_BILLING_ADDRESSES(),
        handleBillingAddressesResponse,
        handleApiError,
        "",
        ""
      );
    } catch (error) {
      setIsLoading(false); // Stop loading state on error
    }
  }, []);

  const handleForeverFreeBilling = async (data: any) => {
    setIsLoading(false);

    try {
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating session:", error);
      // Handle error here
    } finally {
      // Set loading state to false after API call is completed (whether success or error)
    }

    setIsLoading(false); // Stop loading state
  };

  const handleForeverApiError = (error: any) => {
    setIsLoading(false); // Stop loading state on error
  };

  const saveForeverSubscription = useCallback(async (selectedPlan: any) => {
    setIsLoading(true);
    try {
      const requestData = {
        comment: "Free Plan",
        billingEmail: null,
        isAcceptTerms: true,
        firstName: null,
        middleName: null,
        lastName: null,
        subscriptionPlanCode: selectedPlan?.plan?.subscriptionPlanCode,
        address: null,
        address2: null,
        city: null,
        state: null,
        country: null,
        postalCode: null,
        couponCode: null,
      };
      const response = await makeApiCall(
        API_CALL_TYPE.POST_CALL,
        GET_FOREVER_BILLING(),
        handleForeverFreeBilling,
        handleForeverApiError,
        "",
        "",
        requestData
      );
    } catch (error) {
      setIsLoading(false); // Stop loading state on error
    }
  }, []);

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    // const currentUrl = new URL(Config.BROWSER_URL);
    const sessionID = currentUrl.searchParams.get("sessionid");
    const asaConsumerCode = currentUrl.searchParams.get("asaConsumerCode");
    const versionCode = currentUrl.searchParams.get("version");
    Config.X_ASA_version = versionCode ? parseFloat(versionCode) : 1.08; // const asaFiCode = currentUrl.searchParams.get("asaFiCode");
    const token = currentUrl.searchParams.get("token");
    const asaFicode = currentUrl.searchParams.get("asaFintechCode");
    // const asaFintechCode = currentUrl.searchParams.get("asaFintechCode");

    if (asaFicode !== null) {
      const parsedFicode = parseInt(asaFicode, 10);
      if (!isNaN(parsedFicode)) {
        Config.asaFicode = parsedFicode;
      } else {
        // Handle case where asaFicode is not a valid number
        console.error("Invalid asaFicode:", asaFicode);
      }
    }
    let decodedLink: string | null = null;
    const encodedLink = currentUrl.searchParams.get("dynamicLink");
    if (encodedLink !== null) {
      decodedLink = decodeURIComponent(encodedLink); // Assign decodedLink inside the if block
      dispatch(setDynamicLink(decodedLink)); // Dispatch action to save dynamic link
      queryClient.setQueryData("dynamicLink", decodedLink);
    } else {
      console.error("encodedLink is nulldsfdsfdsf");
    } // Declare decodedLink outside the if block
    if (sessionID && asaConsumerCode) {
      // Update Config object with session ID and subscription key
      Config.sessionID = sessionID;
      Config.asaConsumerCode = parseInt(asaConsumerCode);
      // Save session ID to React Query cache
      queryClient.setQueryData("sessionID", sessionID);
      setSessionID(sessionID);
      dispatch(setSessionIDStore(sessionID));
      dispatch(setAsaConsumerCode(Config.asaConsumerCode));

      // if (sessionID) {
      // If sessionID is not set, call getSession to fetch it
      setSessionFetched(true);
      getSession();

      // }
    } else {
      if (UpdatedLocalData) {
        Config.sessionID = UpdatedLocalData?.data?.asaSessionCode;
        Config.asaConsumerCode = parseInt(
          UpdatedLocalData?.data?.asaConsumerCode
        );

        queryClient.setQueryData("sessionID", Config.sessionID);
        setSessionID(Config.sessionID);
        dispatch(setSessionIDStore(UpdatedLocalData?.data?.asaSessionCode));
        dispatch(setAsaConsumerCode(Config.asaConsumerCode));

        const fintechPage = UpdatedLocalData?.sessionPages?.find(
          (page: { pageName: string }) => page.pageName === "FintechName"
        );

        if (fintechPage) {
          const pageData = JSON.parse(fintechPage.pageData);
          if (Array.isArray(pageData) && pageData.length > 0) {
            Config.asaFintechCode = pageData[0].asaFintechCode; // Assuming you want the first one
          }
        }

        // if (sessionID) {
        // If sessionID is not set, call getSession to fetch it
        setSessionFetched(true);
        getSession();
      }

      fetchAllFintech();

      //navigate("/error", { state: "CRB AUTHENTICATION ERROR" });
    }
    // }, [sessionID, Config.asaConsumerCode, sessionFetched]); // Run whenever sessionID changes
  }, [showPlans]); // Run whenever sessionID changes

  const handlePlanClick1 = async (plan: any) => {
    setSelectedPlan(plan); // Set the selected plan
    setSubscriptionType(plan?.billingCycle);
    setsubscriptionAmount(plan?.oneTimeAmount);
    queryClient.setQueryData("selectedPlan", plan);
    setshowPlans(true);

    if (activePlanCodes.has(plan.subscriptionPlanCode)) {
      setPopupMessage(`This Subscription plan is already Active`);
      setShowPopup(true);
    } else {
      try {
        await updateSessiongoal(
          createdSessionData,
          plan,
          "2",
          "GOALS PLANS",
          "Select Package",
          "Select Package",
          "/onegoal",
          "INCOMPLETE"
        );
      } catch (error) {
        setIsLoading(false);
        dispatch(setError("Error fetching session:"));
        console.error("Error fetching session:", error);
        throw error;
      }
    }
  };
  const getPlanCost = (plan: string) => {
    switch (plan) {
      case "FREE":
        return "$0";
      case "ANNUAL":
        return "$49.99 Annual";
      case "LIFETIME":
        return "$99.99 Lifetime";
      default:
        return "";
    }
  };
  const handleBilling = async (selectedPlan: any, activeSubscriptions: any) => {
    // Check for active subscription plan codes
    if (activePlanCodes.has(selectedPlan?.plan?.subscriptionPlanCode)) {
      setPopupMessage(`This Subscription plan is already Active`);
      setShowPopup(true);
      return; // Exit the function if the plan is already active
    }

    if (selectedPlan?.plan?.isFree) {
      saveForeverSubscription(selectedPlan);

      if (UpdatedLocalData) {
        const sessionPages = UpdatedLocalData.data.sessionPages;
        let successDynamicLink = null;

        for (let i = 0; i < sessionPages.length; i++) {
          if (sessionPages[i].pageNumber === 0) {
            const pageData = JSON.parse(sessionPages[i].pageData);
            successDynamicLink = pageData.successDynamicLink;
            break;
          }
        }

        if (successDynamicLink) {
          window.location.href = successDynamicLink;
        } else {
          console.error("Back action dynamic link not found.");
        }
      } else {
        console.error("UpdatedLocalData is not available.");
      }
    } else {
      try {
        await updateSessionBilling(
          createdSessionData,
          selectedPlan?.plan,
          "2",
          "GOALS PLANS",
          "Select Package",
          "Select Package",
          "/moneypatrol",
          "INCOMPLETE"
        );
      } catch (error) {
        setIsLoading(false);
        dispatch(setError("Error fetching session:"));
        console.error("Error fetching session:", error);
        throw error;
      }
    }
  };

  // Function to handle back button click (reset plan selection)

  return (
    <div className="bg-[#121212] flex justify-center items-center min-h-screen">
      {isLoading && <LoaderComponent />}{" "}
      <div className="bg-gradient-to-r from-[#05ACEC] to-[#0051C4] w-full h-screen overflow-y-auto text-white relative z-20">
        <div className="flex items-center justify-evenly space-x-8 pt-8 p-3">
          <div className="back-button-container-money">
            <img
              className="back-button"
              src={arrowBack}
              onClick={onBackButtonClick}
              alt="Back"
            />
          </div>

          <h1 className="font-poppins ml-3 text-[22px] leading-[28px] tracking-[1px] w-[500px] h-[56px] mt-6">
            Choose a plan that
            <br /> works for you.
          </h1>
        </div>

        <img
          src={money_patrol_ui}
          className="relative top-2 left-5 z-50 w-[65px] h-[65px]"
          alt="Flash"
        />

        <div className="bg-[#121212] top-8 rounded-more text-gray-800 mx-auto rounded-t-[3rem] absolute bottom-0 left-0 right-0 h-full overflow-y-auto mt-24">
          <div className="h-full p-6 shadow-lg">
            {selectedPlan ? (
              <div className="bg-[#0B1727] mt-10 text-white w-full \ rounded-lg shadow-lg border-2 border-[#2F3947] p-6 flex flex-col items-center">
                <div className="w-full flex justify-between items-center mb-4">
                  <div className="flex items-center">
                    <img
                      src={back_arrow_money}
                      alt="Back"
                      className="cursor-pointer w-[22px] h-[22px] mr-2"
                      onClick={onBackPlanSelect}
                    />
                    <span className="text-[18px] font-poppins">
                      {selectedPlan.subscriptionTitle}
                    </span>
                  </div>
                  <div className="subscription-title-money">
                    {getPlanCost(selectedPlan.subscriptionTitle)}{" "}
                    {/* Pass the title here */}
                  </div>
                </div>
                <p className="text-[14px] mb-2 leading-[1.4]">
                  {selectedPlan.description}
                </p>

                <p className="text-[14px] mb-4 mt-4">
                  {selectedPlan.subscriptionTitle === "FREE" ? (
                    <>
                      <span className="mr-2">•</span> Access all Money
                      Management features and link up to 2 accounts.
                    </>
                  ) : selectedPlan.subscriptionTitle === "ANNUAL" ? (
                    <>
                      <span className="mr-2">•</span>Access all Money Management
                      features and link all accounts.
                    </>
                  ) : (
                    <>
                      <span className="mr-2">•</span>Access all Money Management
                      features and link all accounts.
                    </>
                  )}
                </p>
              </div>
            ) : (
              <div className="bg-[#121212] text-white h-screen flex flex-col">
                <span className="font-semibold relative -top-4 font-poppins text-[18px] ml-20 text-white">
                  Money Patrol
                </span>
                <div className="flex flex-col mt- items-center justify-center ">
                  <p className="px-6 mb-8 mt-8 font-poppins">
                    Our users have reported an average of $5K+ positive impact
                    on their personal finances.
                  </p>
                  <button className="bg-[#22262c] text-white px-20 py-3 rounded-lg mb-6 font-poppins">
                    15-Day Free Trial
                  </button>
                </div>

                <div className="flex flex-col items-center space-y-6 mb-8">
                  {plans
                    .filter((plan) => plan.subscriptionTitle.trim() !== "") // Filter out empty titles
                    .map((plan) => {
                      // Remove everything after "Annual" or "Lifetime" (including spaces)
                      const displayTitle = plan.subscriptionTitle
                        .replace(/(Annual|Lifetime).*$/, "$1")
                        .toUpperCase();

                      return (
                        <button
                          key={plan.subscriptionPlanId}
                          className="bg-[#0B1727] px-6 py-4 h-[60px] w-full rounded-[15px] flex justify-between items-center border-[2px] border-custom-gray"
                          onClick={() =>
                            handlePlanClick1({
                              subscriptionTitle: displayTitle,
                              description: plan.description,
                              oneTimeAmount: plan.oneTimeAmount,
                              plan: plan,
                            })
                          }
                        >
                          {displayTitle}
                          <img
                            src={circle_right_arrow}
                            alt="Arrow"
                            className="w-6 h-6"
                          />
                        </button>
                      );
                    })}
                </div>
              </div>
            )}

            {selectedPlan && activeSubscriptions && (
              <button
                className="bg-[#006BD2] text-white px-4 py-2 rounded-lg mt-4 fixed bottom-4 left-1/2 transform -translate-x-1/2 max-w-xs w-full font-semibold"
                onClick={() => {
                  handleBilling(selectedPlan, activeSubscriptions);
                }}
              >
                {
                  // Check if the selected plan is a lifetime plan
                  selectedPlan.plan.subscriptionTitle === "Lifetime Access"
                    ? "PURCHASE LIFETIME"
                    : // Check if the selected plan matches any active subscription
                      activeSubscriptions.some(
                          (subscription) =>
                            subscription.subscriptionPlanCode ===
                            selectedPlan.plan.subscriptionPlanCode
                        )
                      ? // If it matches, check if it's eligible for a free trial
                        selectedPlan?.plan?.isConsumerEligibleForTrial
                        ? "START FREE TRIAL"
                        : "SUBSCRIBE"
                      : // If it doesn't match, check if it's a free plan
                        selectedPlan.plan.subscriptionTitle === "FREE"
                        ? "FOREVER FREE"
                        : "START FREE TRIAL" // Default to free trial if not matched
                }
              </button>
            )}
          </div>

          {/* Enhanced Popup Modal */}
          {showPopup && (
            <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ease-in-out">
              <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm mx-4 sm:mx-8 lg:mx-12">
                <div className="flex flex-col items-center">
                  <div className="text-center mb-4">
                    <p className="font-poppins text-xl font-poppins text-gray-800">
                      {popupMessage}
                    </p>
                  </div>
                  <button
                    className="bg-[#006BD2] text-white px-4 py-2 rounded-md font-semibold hover:bg-[#169b6b] transition-colors duration-200"
                    onClick={() => setShowPopup(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MoneyPatrolLanding;
