import React, { useState, useEffect, useCallback, useRef } from "react";
import logo_dark_rent from "../assets/Images/logo_dark_rent.png";
import Layer_1 from "../assets/Images/landing/event/speakers/Layer_1.png";
import Layer_2 from "../assets/Images/landing/event/speakers/Layer_2.png";
import Layer_3 from "../assets/Images/landing/event/speakers/Layer_3.png";
import Layer_4 from "../assets/Images/landing/event/speakers/Layer_4.png";
import rent_reporting from "../assets/Images/rent_reporting.png";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import "../assets/css/style.css";

import { useNavigate } from "react-router-dom";
import { Config } from "../Utils/AppConstants";
import { makeApiCall } from "../services/ApiService";
import { API_CALL_TYPE, SAVE_SESSION, UPDATE_SESSION } from "../services/Api";
import { useQueryClient } from "react-query";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined"; // import preline from "../assets/libs/preline/preline";
import StarRateIcon from "@mui/icons-material/StarRate";
import Swiper from "swiper";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Slider from "react-slick";
import {
  setSessionData,
  setLoading,
  setError,
  setClickedHeader,
  setDynamicLink,
} from "../redux/sessionSlice";
import { SessionData, SessionUpdateRequest } from "../types/types";
import { ToastContentProps, toast } from "react-toastify";
import LoaderComponent from "../components/LoaderComponent";

export default function LandingScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const queryClient = useQueryClient();
  const [getSessionCalled, setGetSessionCalled] = useState(false);
  const swiperRef = useRef<HTMLDivElement | null>(null); // Specify the correct type
  const [token, setToken] = useState<string | null>(null);
  const [createdSessionData, setcreatedSessionData] =
    useState<SessionData | null>(null); // Define state variable
  const [sessionID, setSessionID] = useState<string | null>(null);
  const [sessionFetched, setSessionFetched] = useState(false); // Track if session has been fetched
  const sessionData: SessionData | undefined =
    queryClient.getQueryData("sessionData");
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const showCustomToast = (
    message:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | ((props: ToastContentProps<unknown>) => React.ReactNode)
      | null
      | undefined,
    isError: boolean = false // Add a parameter to specify if it's an error message
  ) => {
    // Determine the toast type based on whether it's an error or success
    const toastType = isError ? toast.error : toast.success;

    // Show the toast message
    toastType(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: isError ? "#A2252C" : "#4BB543", // Set the background color
        color: "white", // Set the text color
        fontFamily: "Poppins", // Set the font family
      },
      bodyClassName: "poppins-font", // Add a class for additional styling
    });
  };

  const getSession = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL, // Specify the API call type (GET in this case)
        SAVE_SESSION(Config.sessionID), // API endpoint to fetch session data
        handleSessionResponse, // Callback function to handle successful response
        handleSessionError, // Callback function to handle error response
        "", // Placeholder for formData argument
        "", // Placeholder for token argument
        "" // Placeholder for body argument
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching session:", error);
      if (!getSessionCalled) {
        setGetSessionCalled(true);
        getSession();
      }
      throw error;
    }
  }, []);

  useEffect(() => {
    setShowLoader(isLoading); // Show loader when isLoading is true
  }, [isLoading]);

  // Check the PWA URL parameters
  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    // const currentUrl = new URL(Config.BROWSER_URL);
    const path = currentUrl.pathname;
    const sessionID = currentUrl.searchParams.get("sessionid");
    const asaConsumerCode = currentUrl.searchParams.get("asaConsumerCode");
    const versionCode = currentUrl.searchParams.get("version");
    Config.X_ASA_version = versionCode ? parseFloat(versionCode) : 1.08; // const asaFiCode = currentUrl.searchParams.get("asaFiCode");
    const token = currentUrl.searchParams.get("token");
    const asaFicode = currentUrl.searchParams.get("asaFiCode");
    if (asaFicode !== null) {
      const parsedFicode = parseInt(asaFicode, 10);
      if (!isNaN(parsedFicode)) {
        Config.asaFicode = parsedFicode;
      } else {
        // Handle case where asaFicode is not a valid number
        console.error("Invalid asaFicode:", asaFicode);
      }
    }
    let decodedLink: string | null = null;
    const encodedLink = currentUrl.searchParams.get("dynamicLink");
    if (encodedLink !== null) {
      decodedLink = decodeURIComponent(encodedLink); // Assign decodedLink inside the if block
      dispatch(setDynamicLink(decodedLink)); // Dispatch action to save dynamic link
      queryClient.setQueryData("dynamicLink", decodedLink);
    } else {
      console.error("encodedLink is null");
    } // Declare decodedLink outside the if block
    if (sessionID && token && asaConsumerCode && !getSessionCalled) {
      // Update Config object with session ID and subscription key
      Config.sessionID = sessionID;
      Config.asaConsumerCode = parseInt(asaConsumerCode);
      // Save session ID to React Query cache
      queryClient.setQueryData("sessionID", sessionID);
      setSessionID(sessionID);
      // if (sessionID) {
      // If sessionID is not set, call getSession to fetch it
      setSessionFetched(true);
      getSession();
      // }
    } else {
      navigate("/error", { state: "CRB AUTHENTICATION ERROR" });
    }
    // }, [sessionID, Config.asaConsumerCode, sessionFetched]); // Run whenever sessionID changes
  }, []); // Run whenever sessionID changes

  /*
    Handle updating session API CALL
 */

  const updateSession = async (_createdSessionDataifany: any) => {
    dispatch(setLoading(true));
    setIsLoading(true);
    const sessionUpdateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-CRB",
      pageCount: _createdSessionDataifany?.pageCount,
      status: "Incomplete",
      statusMessage: _createdSessionDataifany?.statusMessage,
      sessionPages: [
        {
          asaSessionCode: Config.sessionID, // Use the session ID
          pageNumber: "1", // Page number, could be a string or a number depending on your API requirements
          pageName: "Landing", // Name of the page (e.g., "Landing")
          pageTitle: "Select Package", // Title of the page (e.g., "Select Package")
          pageDescription: "Select Package for  Rent", // Description of the page
          pageUrl: "/crb", // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: "Landing Page", // Status message for the page
          pageData: "{}", // Additional data related to the page, could be empty or contain serialized data
        },
      ],
    };

    try {
      const sessionID: string = queryClient.getQueryData("sessionID") as string;
      if (!sessionID) {
        throw new Error("Session ID not found");
      }
      const response = makeApiCall(
        API_CALL_TYPE.PUT_CALL, // Specify the API call type (GET in this case)
        UPDATE_SESSION(), // API endpoint to fetch session data
        handleUpdateSessionResponse, // Callback function to handle successful response
        handleUpdateSessionError, // Callback function to handle error response
        "", // Placeholder for formData argument
        "", // Placeholder for token argument
        sessionUpdateRequest // Pass the session ID in the body // Placeholder for body argument
      );
      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error fetching session:"));
      console.error("Error fetching session:", error);
      throw error;
    }
  };

  // Handling Update Session response

  const handleUpdateSessionResponse = (response: SessionUpdateRequest) => {
    if (response && response?.status == "400") {
      // Handle the invalid session error here
      console.error("Invalid session error:", response.message);
      // You can show a toast message to the user or navigate to an error page
      navigate("/error", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases

      dispatch(setSessionData(response));
      setIsLoading(false);
      queryClient.setQueryData("updatedSessionData", response);

      // Dispatch actions, update state, etc.
    }
  };

  // Handling Update Session Error

  const handleUpdateSessionError = (_error: any) => {
    setIsLoading(false);
    dispatch(setLoading(false));
  };

  // Handling GET Session API CALL

  const handleSessionResponse = async (response: any) => {
    // console.log("Authentication ", "" + JSON.stringify(response));
    // // Handle successful session response
    setIsLoading(false);
    if (response && response.data && response.data.asaSessionCode) {
      // Session ID is present, trigger update call or perform other actions
      queryClient.setQueryData("sessionData", response);
      setcreatedSessionData(response?.data);
      showCustomToast(response?.message, false);
      // Call updateSession and wait for the response
      await updateSession(response?.data);
    } else if (response && response?.status == "400") {
      // Handle the invalid session error here,
      console.error("Invalid session error:", response.message);
      // You can show a toast message to the user or navigate to an error page
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/error", { state: "Invalid Session" });
    } else {
      setIsLoading(false);
      // Session ID not found in the response
      console.log("Session ID not found in the response");
    }
  };

  /*
   * Handling errors in the response callback after a
   * GET request to the session endpoint
   */

  const handleSessionError = (error: any) => {
    navigate("/error", { state: "CRB AUTHENTICATION ERROR" });
  };

  function scrollToSection(sectionId: string): () => void {
    return function () {
      // Find the target section by ID
      const section = document.getElementById(sectionId);
      // Scroll to the target section smoothly
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    };
  }
  // On Selecting any package

  const handleClick =
    (header: string, _token: string | null, _sessionID: string | null) =>
    async (e: { preventDefault: () => void }) => {
      // Prevent the default behavior of the anchor tag
      e.preventDefault();
      dispatch(setClickedHeader(header)); // Dispatch the action with the clicked header text
      // Check if session ID is validated
      if (sessionID && sessionFetched) {
        // Get the data you want to pass to the next screen
        const data = {
          clickedHeader: header,
        };

        // Call getSession and updateSession
        await getSession();
        await updateSession(createdSessionData);
        const updatedSessionData =
          queryClient.getQueryData("updatedSessionData");
        if (updatedSessionData) {
          // Navigate to the "auth" screen with the data
          navigate("/auth", { state: data });
        } else {
          // Show a toast message indicating missing session data
          showCustomToast("Session data not available", true);
        }
      } else {
        // Show a toast message indicating invalid session ID
        showCustomToast("Invalid session ID or session not fetched", true);
      }
    };

  /*
   * Landing Screen
   * JSX
   */

  return sessionID ? (
    <div className="max-w-full ">
      {isLoading && <LoaderComponent />}{" "}
      <section id="home" className="py-20 relative overflow-hidden">
        <span className="bg-white"></span>
        <div className="container z-10">
          <div className="">
            {" "}
            {/*grid lg:grid-cols-2 grid-cols-1 gap-12 items-center*/}
            <div className="">
              <div
                className=""
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "grid",
                }}
              >
                <img src={logo_dark_rent} className="mb-5" alt="Opixo Logo" />
              </div>
              <h1
                className="md:text-5xl/tight text-4xl font-bold text-gray-900"
                style={{ textAlign: "center" }}
              >
                {" "}
                {/*max-w-lg mb-4*/}Increase Your Credit Score Using Your Rent
              </h1>
              <p
                className="md:text-lg text-slate-700"
                style={{ textAlign: "center", marginTop: "10px" }}
              >
                Rent Payments can Increase your Credit Score
              </p>
              <div
                className="flex flex-wrap items-center gap-5"
                style={{ justifyContent: "center" }}
              >
                <a
                  href="#"
                  onClick={scrollToSection("price")}
                  className="py-3 px-7 font-medium text-white bg-color-A2252C rounded-md shadow hover:shadow-lg hover:shadow-primary/20 dark:shadow-primary/20"
                  style={{ marginTop: "15px" }}
                >
                  Get started <TrendingUpIcon />
                </a>
                <a href="#" className="text-primary flex items-center"></a>
              </div>

              {/* Flex End */}
            </div>
          </div>
          {/* Grid End */}
        </div>
        {/* Container End */}
        <div className="absolute sm:-bottom-px -bottom-[1px] start-0 end-0 overflow-hidden text-white">
          <svg
            className="w-full scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </section>
      <section
        id="price"
        className="sm:py-20 py-10 bg-white dark:bg-neutral-900"
      >
        <div className="container">
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">
            <div className="bg-white border-b-2 rounded-lg transition-all duration-500 border-b-primary hover:-translate-y-3 bg-color-F6F6F6">
              <div className="p-6">
                <h3 className="text-4xl font-bold text-gray-800 mb-10">
                  Ongoing Rent Reporting<sub className="text-lg"></sub>
                </h3>
                <ul className="mb-6">
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <VerifiedOutlinedIcon />
                    </div>
                    <p className="text-gray-900">
                      {" "}
                      $6.95/ Month or $49.00/ Year
                    </p>
                  </li>
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <VerifiedOutlinedIcon />
                    </div>
                    <p className="text-gray-900"> Discounts For Roommates</p>
                  </li>
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <VerifiedOutlinedIcon />
                    </div>
                    <p className="text-gray-900"> Score Increases Over Time</p>
                  </li>
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <VerifiedOutlinedIcon />
                    </div>
                    <p className="text-gray-900">
                      {" "}
                      Results In Less Than 2 Weeks
                    </p>
                  </li>
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <VerifiedOutlinedIcon />
                    </div>
                    <p className="text-gray-900">
                      {" "}
                      Reporting From Today Forward
                    </p>
                  </li>
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <VerifiedOutlinedIcon />
                    </div>
                    <p className="text-gray-900"> No Credit Inquiry</p>
                  </li>
                </ul>
                <a
                  onClick={handleClick(
                    "Ongoing Rent Reporting",
                    token,
                    sessionID
                  )}
                  className="flex items-center justify-center  border border-dashed font-bold border-primary text-primary px-4 py-2 transition-all duration-200 rounded-md hover:bg-primary hover:text-white"
                >
                  SELECT
                </a>
              </div>
            </div>
            <div
              className="bg-white border-b-2 rounded-lg transition-all duration-500 border-b-primary hover:-translate-y-3"
              style={{ backgroundColor: "#F6F6F6" }}
            >
              <div className="p-6">
                <h3 className="text-4xl font-bold text-gray-800 mb-10">
                  Past Rent <br /> Reporting<sub className="text-lg"></sub>
                </h3>
                <ul className="mb-6">
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <VerifiedOutlinedIcon />
                    </div>
                    <p className="text-gray-900">
                      {" "}
                      $49.00 12mo's or $65.00 24mo's
                    </p>
                  </li>
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <VerifiedOutlinedIcon />
                    </div>
                    <p className="text-gray-900"> Discounts For Roommates</p>
                  </li>
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <VerifiedOutlinedIcon />
                    </div>
                    <p className="text-gray-900">
                      {" "}
                      Avg Score Increase 20-60 Points
                    </p>
                  </li>
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <VerifiedOutlinedIcon />
                    </div>
                    <p className="text-gray-900">
                      {" "}
                      Results In Less than 2 Weeks
                    </p>
                  </li>
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <VerifiedOutlinedIcon />
                    </div>
                    <p className="text-gray-900">
                      {" "}
                      Up To The Last 2 Years Of History
                    </p>
                  </li>
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <VerifiedOutlinedIcon />
                    </div>
                    <p className="text-gray-900"> No Credit Inquiry</p>
                  </li>
                </ul>
                <a
                  onClick={handleClick("Past Rent Reporting", token, sessionID)}
                  className="flex items-center justify-center border font-poppins border-dashed font-bold border-primary text-primary px-4 py-2 transition-all duration-200 rounded-md hover:bg-primary hover:text-white"
                >
                  SELECT
                </a>
              </div>
            </div>
            <div
              className="bg-white border-b-2 rounded-lg transition-all duration-500 border-b-primary hover:-translate-y-3"
              style={{ backgroundColor: "#F6F6F6" }}
            >
              <div className="p-6">
                <h3 className="text-4xl font-bold text-gray-800 mb-10">
                  Past & Ongoing Rent Reporting<sub className="text-lg"></sub>
                </h3>
                <ul className="mb-6">
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <VerifiedOutlinedIcon />
                    </div>
                    <p className="text-gray-900"> $6.95/ Month + Past Option</p>
                  </li>
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <VerifiedOutlinedIcon />
                    </div>
                    <p className="text-gray-900"> Discount For Roommates</p>
                  </li>
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <VerifiedOutlinedIcon />
                    </div>
                    <p className="text-gray-900">
                      {" "}
                      Avg Score Increase 30-100 Points
                    </p>
                  </li>
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <VerifiedOutlinedIcon />
                    </div>
                    <p className="text-gray-900">
                      {" "}
                      Results In Less than 2 Weeks
                    </p>
                  </li>
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <VerifiedOutlinedIcon />
                    </div>
                    <p className="text-gray-900">
                      {" "}
                      Up To The Last 2 Years Of History
                    </p>
                  </li>
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <VerifiedOutlinedIcon />
                    </div>
                    <p className="text-gray-900"> No Credit Inquiry</p>
                  </li>
                </ul>
                <a
                  onClick={handleClick(
                    "Past & Ongoing Rent Reporting",
                    token,
                    sessionID
                  )}
                  className="flex items-center justify-center border border-dashed font-poppins font-bold border-primary text-primary px-4 py-2 transition-all duration-200 rounded-md hover:bg-primary hover:text-white"
                >
                  SELECT
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="speakers"
        className="py-20"
        style={{ backgroundColor: "#F6F6F6" }}
      >
        <div className="container">
          <div className="flex items-center justify-center mb-16">
            <div className="max-w-2xl text-center">
              <h2 className="text-3xl/snug font-bold capitalize text-gray-800 mb-1.5">
                Trusted By 100,000 + Tenants, Landlords, And Apartment
                Communities Throughout The Entire US
              </h2>
            </div>
          </div>

          <div className="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px]">
            <div className="lg:col-span-3 md:col-span-6">
              <div className="group text-center">
                <div className="relative inline-block mx-auto rounded-lg overflow-hidden">
                  <img src={Layer_1} className="" alt="" />
                </div>
              </div>
            </div>

            <div className="lg:col-span-3 md:col-span-6">
              <div className="group text-center">
                <div className="relative inline-block mx-auto rounded-lg overflow-hidden">
                  <img src={Layer_2} className="" alt="" />
                </div>
              </div>
            </div>

            <div className="lg:col-span-3 md:col-span-6">
              <div className="group text-center">
                <div className="relative inline-block mx-auto rounded-lg overflow-hidden">
                  <img src={Layer_3} className="" alt="" />
                </div>
              </div>
            </div>

            <div className="lg:col-span-3 md:col-span-6">
              <div className="group text-center">
                <div className="relative inline-block mx-auto rounded-lg overflow-hidden">
                  <img src={Layer_4} className="" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="sm:py-20 pt-20 pb-10">
        <div className="container">
          <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-10">
            <div className="lg:order-2">
              <div className="grid items-center">
                <img
                  src={rent_reporting}
                  className="max-w-full max-h-full rounded-md"
                  alt=""
                />
              </div>
            </div>
            <div className="lg:me-6">
              <div className="my-auto">
                <h2 className="text-3xl text-gray-800 font-bold mb-4">
                  Welcome to{" "}
                  <span className="text-primary">Credit Rent Boost!</span>
                </h2>
                <div className="flex flex-col mb-6">
                  <p className="text-lg font-medium text-slate-500 mb-6 pe-2">
                    You may be wondering how to build your credit score. Well
                    don’t worry because CreditRentBoost.com has your back!
                    CreditRentBoost.com, a subsidiary of 950 Credit, Inc., works
                    directly with both tenants and landlords to improve credit
                    scores by reporting monthly rent payments to TransUnion and
                    Equifax. Unlike most property management companies and
                    landlords who are unable to provide such services due to
                    stringent guidelines and vetting processes, we offer rent
                    reporting services to all those named on the lease
                    agreement. Consequently, spouses and roommates can benefit
                    from the credit boost resulting from their payments, leading
                    to faster credit score improvement. Additionally, we can
                    report the past 24 months of rent payments which gives you
                    an immediate 2 year credit history. This is particularly
                    beneficial as research shows that the longer the tradeline
                    reports, the greater its impact on your score. And the best
                    part of all – there’s no credit inquiry needed!
                  </p>

                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <CheckCircleOutlineIcon />
                    </div>
                    <p className="text-slate-500"> Innovative</p>
                  </li>

                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <CheckCircleOutlineIcon />
                    </div>
                    <p className="text-slate-500"> Real People</p>
                  </li>

                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <CheckCircleOutlineIcon />
                    </div>
                    <p className="text-slate-500"> Safe & Secure</p>
                  </li>

                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <CheckCircleOutlineIcon />
                    </div>
                    <p className="text-slate-500"> BBB Accredited</p>
                  </li>
                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <CheckCircleOutlineIcon />
                    </div>
                    <p className="text-slate-500"> Quick Results</p>
                  </li>

                  <li className="flex items-center gap-2 mb-4 font-medium text-base">
                    <div className="text-red-A2252C">
                      <CheckCircleOutlineIcon />
                    </div>
                    <p className="text-slate-500"> Immediate History</p>
                  </li>
                </div>

                <a
                  href="#"
                  className="group relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none text-primary hover:text-primary duration-500"
                >
                  <span className="absolute h-px w-7/12 group-hover:w-full transition-all duration-500 rounded bg-primary/80 -bottom-0"></span>
                  Learn More{" "}
                  <i className="uil uil-angle-right text-xl/none align-middle"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="features" className="py-20 bg-color-F6F6F7">
        <div className="container">
          <div className="flex items-center justify-center mb-14">
            <div className="max-w-2xl text-center">
              <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-bold uppercase how text-primary mb-3">
                <span className="w-1.5 h-1.5 inline-block bg-color-A2252C rounded-full"></span>
                HOW IT WORKS
              </span>
              <h1 className="text-3xl/snug font-bold capitalize text-gray-800 mb-1">
                3 Easy Steps To A Better Credit Score!
              </h1>
              <p className="text-base text-gray-600">
                Our goal is to give you, the tenant, credit for something you
                already pay for! <br />
                <b>View your new Credit Score in as little as two weeks!</b>
              </p>
            </div>
          </div>

          <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">
            <div className="bg-white rounded-xl shadow-[0_8px_48px_#eee] group">
              <div className="rounded-lg p-6 text-center shrink-0">
                <div className="inline-flex items-center justify-center rounded-full">
                  <FmdGoodOutlinedIcon sx={{ fontSize: 40 }} />
                </div>
                <h3 className="md:text-2xl text-xl font-bold text-gray-800 mb-2 mt-6">
                  Complete Enrollment Process & Submit Photo ID
                </h3>
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-[0_8px_48px_#eee] group">
              <div className="rounded-lg p-6 text-center shrink-0">
                <div className="inline-flex items-center justify-center rounded-full">
                  <ErrorOutlineIcon sx={{ fontSize: 40 }} />
                </div>
                <h3 className="md:text-2xl text-xl font-bold text-gray-800 mb-2 mt-6">
                  We Verify Your Landlord & Your Rent Payment
                </h3>
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-[0_8px_48px_#eee] group">
              <div className="rounded-lg p-6 text-center shrink-0">
                <div className="inline-flex items-center justify-center rounded-full">
                  <PhoneAndroidIcon sx={{ fontSize: 40 }} />
                </div>
                <h3 className="md:text-2xl text-xl font-bold text-gray-800 mb-2 mt-6">
                  Your New Account Is Reported To TransUnion & Equifax
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="testimonial" className="py-20">
        <div className="container">
          <div className="flex items-center justify-center mb-14">
            <div className="max-w-2xl text-center">
              <h2 className="text-3xl/snug font-bold capitalize text-gray-800 mb-1">
                What Our Customers Say
              </h2>
            </div>
          </div>

          <div>
            <Slider {...settings}>
              <div>
                <div className="swiper-slide">
                  <div className="flex flex-col justify-center items-center text-center">
                    <h3 className="text-lg font-bold text-gray-800 mt-5">
                      Randall Liles
                    </h3>
                    <p className="text-base font-semibold text-gray-500">
                      16/10/2023
                    </p>
                    <div className="flex items-center justify-center mt-5 text-yellow-FCD34D">
                      <StarRateIcon />
                      <StarRateIcon />
                      <StarRateIcon />
                      <StarRateIcon />
                      <StarRateIcon />
                    </div>
                    <p className="text-base font-medium mt-5 max-w-2xl">
                      “ This is a great company. They are thorough and very
                      helpful. They personally helped my landlord through every
                      step of the process.”
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div className="swiper-slide">
                  <div className="flex flex-col justify-center items-center text-center">
                    <h3 className="text-lg font-bold text-gray-800 mt-5">
                      TenderCareFlorida
                    </h3>
                    <p className="text-base font-semibold text-gray-500">
                      01/05/2024
                    </p>
                    <div className="flex items-center justify-center mt-5 text-yellow-FCD34D">
                      <StarRateIcon />
                      <StarRateIcon />
                      <StarRateIcon />
                      <StarRateIcon />
                      <StarRateIcon />
                    </div>
                    <p className="text-base font-medium mt-5 max-w-2xl">
                      “ I absolutely love this company. They helped me when i
                      needed my score to be increased. ”
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div className="swiper-slide">
                  <div className="flex flex-col justify-center items-center text-center">
                    <h3 className="text-lg font-bold text-gray-800 mt-5">
                      FaithWorks
                    </h3>
                    <p className="text-base font-semibold text-gray-500">
                      25/04/2024
                    </p>
                    <div className="flex items-center justify-center mt-5 text-yellow-FCD34D">
                      <StarRateIcon />
                      <StarRateIcon />
                      <StarRateIcon />
                      <StarRateIcon />
                      <StarRateIcon />
                    </div>
                    <p className="text-base font-medium mt-5 max-w-2xl">
                      “ The best service ever they are an excellent reporting
                      tool for ANYONE renting and not getting credit for it.
                      They are awesome! ”
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div className="swiper-slide">
                  <div className="flex flex-col justify-center items-center text-center">
                    <h3 className="text-lg font-bold text-gray-800 mt-5">
                      Jordan Carr
                    </h3>
                    <p className="text-base font-semibold text-gray-500">
                      17/04/2024
                    </p>
                    <div className="flex items-center justify-center mt-5 text-yellow-FCD34D">
                      <StarRateIcon />
                      <StarRateIcon />
                      <StarRateIcon />
                      <StarRateIcon />
                      <StarRateIcon />
                    </div>
                    <p className="text-base font-medium mt-5 max-w-2xl">
                      “ Great company! Elsa was very helpful and understanding
                      and kept up great communication. I definitely recommend.
                      Will be doing business with them again. ”
                    </p>
                  </div>
                </div>
              </div>

              {/* Navigation Buttons */}
              {/* <div className="md:flex justify-between mt-4">
              <div className="text-red-A2252C ">
                <SwipeLeftAltOutlinedIcon fontSize="large" />
              </div>
              <div className="text-red-A2252C ">
                <SwipeRighttAltOutlinedIcon fontSize="large" />
              </div>
            </div> */}
            </Slider>

            {/* <div className="swiper-pagination"></div>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div> */}
          </div>
        </div>
      </section>
      <footer
        className="bg-center bg-no-repeat bg-slate-900 bg-color-A2252C"
        style={{ backgroundImage: `url('..assets/Images/footer_bg.png')` }}
      >
        <div className="border-t border-slate-700/20 h-[75px]">
          <div className="container lg:px-20 flex flex-wrap justify-center items-center h-full md:justify-between text-center md:text-start">
            <p className="text-base font-medium text-white">
              {new Date().getFullYear()} © Credit Rent Boost - All Rights
              Reserved.
            </p>
            <p className="text-base font-medium text-white">
              <a>Terms & Conditions and </a>
              <a>Privacy Policy</a>
            </p>
          </div>{" "}
          {/* Flex End */}
        </div>{" "}
        {/* Container End */}
      </footer>
    </div>
  ) : null;
}
