export const Config = {
   API_URL: "https://api.asavault.com/",
  X_ASA_version: 1.08,
  sessionID: "",
  subscriptionKey: "",
  token: "",
  asaFicode: 12345679, // Declare type as string or null
  asaConsumerCodee: "",
  asaConsumerCode: 2037376870,
  dynamicLink: "",
  asaFintechCode: 12345679,
  isLight:false,



  AUTHENTICATE_URL: `https://appdev.asavault.com/authenticate?
request=FintechBillingPage
&token=njhutsb
&asaFintechCode=618038590
&asaFintechApplicationCode=124
&asaConsumerCode=375427068
&SubscriptionPlanCode=16
&IsNewSubscription=YES
&IsTrialEligible=NO
&Amount=125
&CouponCode=BUY2024
&PrevSubscriptionPlanCode=0
&redirecturl=https://portfoliopilot.com/asa/asa_landing?confirmation=&message=
&redirecturlonError=https://portfoliopilot.com/asa/asa_landing/error?message=Authentication%20Failed
`,
// &asaConsumerCode=1685949699
//    AUTHENTICATE_URL: `https://appdev.asavault.com/authenticate?
// request=FintechBillingPage
// &asaFintechCode=618038590
// &asaConsumerCode=939168496
// &redirecturl=https://sandbox.portfoliopilot.com/track?onboarding=complete&view=Preferences?sync=true
// &SubscriptionPlanCode=16
// &IsNewSubscription=YES&IsTrialEligible=YES&Amount=99.0&token=undefined&redirecturlonError=https://sandbox.portfoliopilot.com/track?onboarding=complete&view=Preferences
// `,




  // AUTHENTICATE_URL: `https://appdev.asavault.com/authenticate
// `,

  BROWSER_URL: "https://appdev.asavault.com/guac?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzMDExIiwiVXNlck5hbWUiOiJtMTBAbWFpbC5jb20iLCJBc2FDb25zdW1lckNvZGUiOiI0NjM4MjI4MDUiLCJUb2tlblRhZyI6ImI4ZjkxNGY3LTZjNjEtNDliMi1iYTc2LTAyMGE3N2Y0MmI1NyIsIkRldmljZUlkIjoiRUZBNzVFNzgtQzdCNS00QUU3LTg1RDEtQkQzOTAyREZFQzlGIiwiRXhwaXJhdGlvblRpbWUiOiIyMDI1LTAxLTE4VDA2OjUyOjIzLjc5NzUzMTZaIiwianRpIjoiOWUzMzJkY2QtNDkxYi00YjFmLWE2YTEtMzAzMDJjMWQyNDEzIiwibmJmIjoxNzIxNjMxMTQzLCJleHAiOjE3MzcxODMxNDMsImlhdCI6MTcyMTYzMTE0MywiaXNzIjoiaHR0cHM6Ly9hc2Fjb25uZWN0ZGV2LmFzYWNvcmUuY29tIiwiYXVkIjoiaHR0cHM6Ly9hc2Fjb25uZWN0ZGV2LmFzYWNvcmUuY29tIn0.y2KIEURfLIBs49nRRQ_HlI5vbWwVRqySRXRSFE4pf8Y&sessionid=1705922456&asaFintechCode=1282849331&asaConsumerCode=601694715&dynamicLink=https%3A%2F%2Fasavault.page.link%2F6NxospvkNp1tcw7Q9&version=1.08",

  // "https://appdev.asavault.com/onegoal?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzNDQ1IiwiVXNlck5hbWUiOiJneWFuZXNoLmthbWFsQGFzYS5maW5hbmNpYWwiLCJBc2FDb25zdW1lckNvZGUiOiIxNDIyNTMxODkiLCJUb2tlblRhZyI6IjIxNWM3ZDBjLTJhNDYtNGQ5My04OWU3LTViZjRjMTk1MzBhMyIsIkRldmljZUlkIjoiZjM2ZGQ2MWZlOTAyYWVlZCIsIkV4cGlyYXRpb25UaW1lIjoiMjAyNS0wMS0xN1QwNjoxNTozOS4zMDIzMzc5WiIsImp0aSI6IjQ2YmI3YTBhLWQwZTktNGNjOC1iNTMyLTc1NzkwNmI0NDYyYiIsIm5iZiI6MTcyMTU0MjUzOSwiZXhwIjoxNzM3MDk0NTM5LCJpYXQiOjE3MjE1NDI1MzksImlzcyI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSJ9.dXgE19hmc2QVcKZKbAcbqUfcFd1qYALpMOOb7wcuNY0&sessionid=184239608&asaFintechCode=1054208782&asaConsumerCode=50581622&dynamicLink=https://asavault.page.link/eCKt7N8DCBYgTNaFA&version=1.08",

// "https://appdev.asavault.com/moneypatrol?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzMDExIiwiVXNlck5hbWUiOiJNMTBAbWFpbC5jb20iLCJBc2FDb25zdW1lckNvZGUiOiI0NjM4MjI4MDUiLCJUb2tlblRhZyI6Ijg1ZTZlNjFhLTU4ZmUtNDliOS04NzU3LTMyZDU2ODZkY2Y4ZSIsIkRldmljZUlkIjoiMzZDNzhFODktMURCQS00N0FBLUFFMTEtNkJCQkU1NkU3REFBIiwiRXhwaXJhdGlvblRpbWUiOiIyMDI1LTAzLTMwVDA5OjUxOjQ0Ljg1MjMwOThaIiwianRpIjoiN2RjY2IyM2MtNWM4Mi00ZDczLWFjZjgtZDY0ZDU1YzY0M2U4IiwibmJmIjoxNzI3Nzc2MzA0LCJleHAiOjE3NDMzMjgzMDQsImlhdCI6MTcyNzc3NjMwNCwiaXNzIjoiaHR0cHM6Ly9saW5rZmlkZXYuYXNhY29yZS5jb20iLCJhdWQiOiJodHRwczovL2xpbmtmaWRldi5hc2Fjb3JlLmNvbSJ9.cFRDf2imEt314goYqA7fGUlyzxqcYRCmWAQuX8p8rvY&sessionid=1190303967&asaFintechCode=688487018&asaConsumerCode=262165851&dynamicLink=https%3A%2F%2Fwww.moneypatrol.com%2F&version=1.08",  
  // "https://appdev.asavault.com/hireandfireKids?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzMDExIiwiVXNlck5hbWUiOiJtMTBAbWFpbC5jb20iLCJBc2FDb25zdW1lckNvZGUiOiI0NjM4MjI4MDUiLCJUb2tlblRhZyI6ImI4ZjkxNGY3LTZjNjEtNDliMi1iYTc2LTAyMGE3N2Y0MmI1NyIsIkRldmljZUlkIjoiRUZBNzVFNzgtQzdCNS00QUU3LTg1RDEtQkQzOTAyREZFQzlGIiwiRXhwaXJhdGlvblRpbWUiOiIyMDI1LTAxLTE4VDA2OjUyOjIzLjc5NzUzMTZaIiwianRpIjoiOWUzMzJkY2QtNDkxYi00YjFmLWE2YTEtMzAzMDJjMWQyNDEzIiwibmJmIjoxNzIxNjMxMTQzLCJleHAiOjE3MzcxODMxNDMsImlhdCI6MTcyMTYzMTE0MywiaXNzIjoiaHR0cHM6Ly9hc2Fjb25uZWN0ZGV2LmFzYWNvcmUuY29tIiwiYXVkIjoiaHR0cHM6Ly9hc2Fjb25uZWN0ZGV2LmFzYWNvcmUuY29tIn0.y2KIEURfLIBs49nRRQ_HlI5vbWwVRqySRXRSFE4pf8Y&sessionid=1343911788&asaFintechCode=1479515214&asaConsumerCode=1297891905&dynamicLink=https%3A%2F%2Fasavault.page.link%2F6NxospvkNp1tcw7Q9&version=1.08",
// "https://appdev.asavault.com/moneypatrol?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzMDExIiwiVXNlck5hbWUiOiJNMTBAbWFpbC5jb20iLCJBc2FDb25zdW1lckNvZGUiOiI0NjM4MjI4MDUiLCJUb2tlblRhZyI6Ijg1ZTZlNjFhLTU4ZmUtNDliOS04NzU3LTMyZDU2ODZkY2Y4ZSIsIkRldmljZUlkIjoiMzZDNzhFODktMURCQS00N0FBLUFFMTEtNkJCQkU1NkU3REFBIiwiRXhwaXJhdGlvblRpbWUiOiIyMDI1LTAzLTMwVDA5OjUxOjQ0Ljg1MjMwOThaIiwianRpIjoiN2RjY2IyM2MtNWM4Mi00ZDczLWFjZjgtZDY0ZDU1YzY0M2U4IiwibmJmIjoxNzI3Nzc2MzA0LCJleHAiOjE3NDMzMjgzMDQsImlhdCI6MTcyNzc3NjMwNCwiaXNzIjoiaHR0cHM6Ly9saW5rZmlkZXYuYXNhY29yZS5jb20iLCJhdWQiOiJodHRwczovL2xpbmtmaWRldi5hc2Fjb3JlLmNvbSJ9.cFRDf2imEt314goYqA7fGUlyzxqcYRCmWAQuX8p8rvY&sessionid=1654817752&asaFintechCode=714994&asaConsumerCode=397433564&dynamicLink=https%3A%2F%2Fwww.moneypatrol.com%2F&version=1.08",
  // "https://appdev.asavault.com/hireandfireKids?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzMDExIiwiVXNlck5hbWUiOiJtMTBAbWFpbC5jb20iLCJBc2FDb25zdW1lckNvZGUiOiI0NjM4MjI4MDUiLCJUb2tlblRhZyI6ImI4ZjkxNGY3LTZjNjEtNDliMi1iYTc2LTAyMGE3N2Y0MmI1NyIsIkRldmljZUlkIjoiRUZBNzVFNzgtQzdCNS00QUU3LTg1RDEtQkQzOTAyREZFQzlGIiwiRXhwaXJhdGlvblRpbWUiOiIyMDI1LTAxLTE4VDA2OjUyOjIzLjc5NzUzMTZaIiwianRpIjoiOWUzMzJkY2QtNDkxYi00YjFmLWE2YTEtMzAzMDJjMWQyNDEzIiwibmJmIjoxNzIxNjMxMTQzLCJleHAiOjE3MzcxODMxNDMsImlhdCI6MTcyMTYzMTE0MywiaXNzIjoiaHR0cHM6Ly9hc2Fjb25uZWN0ZGV2LmFzYWNvcmUuY29tIiwiYXVkIjoiaHR0cHM6Ly9hc2Fjb25uZWN0ZGV2LmFzYWNvcmUuY29tIn0.y2KIEURfLIBs49nRRQ_HlI5vbWwVRqySRXRSFE4pf8Y&sessionid=693438607&asaFintechCode=1479515214&asaConsumerCode=88952691&dynamicLink=https%3A%2F%2Fasavault.page.link%2F6NxospvkNp1tcw7Q9&version=1.08",
  // "https://appdev.asavault.com/onegoal?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzNDQ1IiwiVXNlck5hbWUiOiJneWFuZXNoLmthbWFsQGFzYS5maW5hbmNpYWwiLCJBc2FDb25zdW1lckNvZGUiOiIxNDIyNTMxODkiLCJUb2tlblRhZyI6IjIxNWM3ZDBjLTJhNDYtNGQ5My04OWU3LTViZjRjMTk1MzBhMyIsIkRldmljZUlkIjoiZjM2ZGQ2MWZlOTAyYWVlZCIsIkV4cGlyYXRpb25UaW1lIjoiMjAyNS0wMS0xN1QwNjoxNTozOS4zMDIzMzc5WiIsImp0aSI6IjQ2YmI3YTBhLWQwZTktNGNjOC1iNTMyLTc1NzkwNmI0NDYyYiIsIm5iZiI6MTcyMTU0MjUzOSwiZXhwIjoxNzM3MDk0NTM5LCJpYXQiOjE3MjE1NDI1MzksImlzcyI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSJ9.dXgE19hmc2QVcKZKbAcbqUfcFd1qYALpMOOb7wcuNY0&sessionid=674557750&asaFintechCode=1054208782&asaConsumerCode=1969087655&dynamicLink=https://asavault.page.link/eCKt7N8DCBYgTNaFA&version=1.08",
  // "https://appdev.asavault.com/guac?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzMDExIiwiVXNlck5hbWUiOiJtMTBAbWFpbC5jb20iLCJBc2FDb25zdW1lckNvZGUiOiI0NjM4MjI4MDUiLCJUb2tlblRhZyI6ImI4ZjkxNGY3LTZjNjEtNDliMi1iYTc2LTAyMGE3N2Y0MmI1NyIsIkRldmljZUlkIjoiRUZBNzVFNzgtQzdCNS00QUU3LTg1RDEtQkQzOTAyREZFQzlGIiwiRXhwaXJhdGlvblRpbWUiOiIyMDI1LTAxLTE4VDA2OjUyOjIzLjc5NzUzMTZaIiwianRpIjoiOWUzMzJkY2QtNDkxYi00YjFmLWE2YTEtMzAzMDJjMWQyNDEzIiwibmJmIjoxNzIxNjMxMTQzLCJleHAiOjE3MzcxODMxNDMsImlhdCI6MTcyMTYzMTE0MywiaXNzIjoiaHR0cHM6Ly9hc2Fjb25uZWN0ZGV2LmFzYWNvcmUuY29tIiwiYXVkIjoiaHR0cHM6Ly9hc2Fjb25uZWN0ZGV2LmFzYWNvcmUuY29tIn0.y2KIEURfLIBs49nRRQ_HlI5vbWwVRqySRXRSFE4pf8Y&sessionid=1627417404&asaFintechCode=1282849331&asaConsumerCode=1961898612&dynamicLink=https%3A%2F%2Fasavault.page.link%2F6NxospvkNp1tcw7Q9&version=1.08",
    // "https://appdev.asavault.com/onegoal?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzNDQ1IiwiVXNlck5hbWUiOiJneWFuZXNoLmthbWFsQGFzYS5maW5hbmNpYWwiLCJBc2FDb25zdW1lckNvZGUiOiIxNDIyNTMxODkiLCJUb2tlblRhZyI6IjIxNWM3ZDBjLTJhNDYtNGQ5My04OWU3LTViZjRjMTk1MzBhMyIsIkRldmljZUlkIjoiZjM2ZGQ2MWZlOTAyYWVlZCIsIkV4cGlyYXRpb25UaW1lIjoiMjAyNS0wMS0xN1QwNjoxNTozOS4zMDIzMzc5WiIsImp0aSI6IjQ2YmI3YTBhLWQwZTktNGNjOC1iNTMyLTc1NzkwNmI0NDYyYiIsIm5iZiI6MTcyMTU0MjUzOSwiZXhwIjoxNzM3MDk0NTM5LCJpYXQiOjE3MjE1NDI1MzksImlzcyI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSJ9.dXgE19hmc2QVcKZKbAcbqUfcFd1qYALpMOOb7wcuNY0&sessionid=2115446798&asaFintechCode=1054208782&asaConsumerCode=294370606&dynamicLink=https://asavault.page.link/eCKt7N8DCBYgTNaFA&version=1.08",
// "https://appdev.asavault.com/onegoal?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzNDQ1IiwiVXNlck5hbWUiOiJneWFuZXNoLmthbWFsQGFzYS5maW5hbmNpYWwiLCJBc2FDb25zdW1lckNvZGUiOiIxNDIyNTMxODkiLCJUb2tlblRhZyI6IjIxNWM3ZDBjLTJhNDYtNGQ5My04OWU3LTViZjRjMTk1MzBhMyIsIkRldmljZUlkIjoiZjM2ZGQ2MWZlOTAyYWVlZCIsIkV4cGlyYXRpb25UaW1lIjoiMjAyNS0wMS0xN1QwNjoxNTozOS4zMDIzMzc5WiIsImp0aSI6IjQ2YmI3YTBhLWQwZTktNGNjOC1iNTMyLTc1NzkwNmI0NDYyYiIsIm5iZiI6MTcyMTU0MjUzOSwiZXhwIjoxNzM3MDk0NTM5LCJpYXQiOjE3MjE1NDI1MzksImlzcyI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSJ9.dXgE19hmc2QVcKZKbAcbqUfcFd1qYALpMOOb7wcuNY0&sessionid=1873936569&asaFintechCode=1054208782&asaConsumerCode=1762062185&dynamicLink=https://asavault.page.link/eCKt7N8DCBYgTNaFA&version=1.08",
  // "https://appdev.asavault.com/crb?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzMDExIiwiVXNlck5hbWUiOiJNMTBAbWFpbC5jb20iLCJBc2FDb25zdW1lckNvZGUiOiI0NjM4MjI4MDUiLCJUb2tlblRhZyI6IjJmMDg5MzZhLWZlNjctNGU5YS1iOWFkLTU5MGZiNGIxOTkxOSIsIkRldmljZUlkIjoiMzZDNzhFODktMURCQS00N0FBLUFFMTEtNkJCQkU1NkU3REFBIiwiRXhwaXJhdGlvblRpbWUiOiIyMDI1LTAzLTE5VDE1OjM3OjIyLjI2NDYyNzlaIiwianRpIjoiOGRiMzliNmYtODQ0Ny00NmFhLTkzZTYtNDZiNjhlNzE1YTg4IiwibmJmIjoxNzI2ODQ2NjQyLCJleHAiOjE3NDIzOTg2NDIsImlhdCI6MTcyNjg0NjY0MiwiaXNzIjoiaHR0cHM6Ly9saW5rZmlkZXYuYXNhY29yZS5jb20iLCJhdWQiOiJodHRwczovL2xpbmtmaWRldi5hc2Fjb3JlLmNvbSJ9.FunUGKg2I9dHMc2mNGBlJR-5fBp2znOfhdBWikCj_ag&sessionid=1903194726&asaFiCode=2017905674&asaConsumerCode=463822805&dynamicLink=https%3A%2F%2Fasavault.page.link%2FCk59NdiVdqDZcKLE7&version=1.08",
// "https://appdev.asavault.com/guac?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzMDExIiwiVXNlck5hbWUiOiJtMTBAbWFpbC5jb20iLCJBc2FDb25zdW1lckNvZGUiOiI0NjM4MjI4MDUiLCJUb2tlblRhZyI6ImI4ZjkxNGY3LTZjNjEtNDliMi1iYTc2LTAyMGE3N2Y0MmI1NyIsIkRldmljZUlkIjoiRUZBNzVFNzgtQzdCNS00QUU3LTg1RDEtQkQzOTAyREZFQzlGIiwiRXhwaXJhdGlvblRpbWUiOiIyMDI1LTAxLTE4VDA2OjUyOjIzLjc5NzUzMTZaIiwianRpIjoiOWUzMzJkY2QtNDkxYi00YjFmLWE2YTEtMzAzMDJjMWQyNDEzIiwibmJmIjoxNzIxNjMxMTQzLCJleHAiOjE3MzcxODMxNDMsImlhdCI6MTcyMTYzMTE0MywiaXNzIjoiaHR0cHM6Ly9hc2Fjb25uZWN0ZGV2LmFzYWNvcmUuY29tIiwiYXVkIjoiaHR0cHM6Ly9hc2Fjb25uZWN0ZGV2LmFzYWNvcmUuY29tIn0.y2KIEURfLIBs49nRRQ_HlI5vbWwVRqySRXRSFE4pf8Y&sessionid=1667756074&asaFintechCode=1282849331&asaConsumerCode=979359348&dynamicLink=https%3A%2F%2Fasavault.page.link%2F6NxospvkNp1tcw7Q9&version=1.08",
  // "https://appdev.asavault.com/crb?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzMDExIiwiVXNlck5hbWUiOiJNMTBAbWFpbC5jb20iLCJBc2FDb25zdW1lckNvZGUiOiI0NjM4MjI4MDUiLCJUb2tlblRhZyI6IjliNzc1MDAzLTUyZTctNGViNi1iODJmLTJlMTBmMGQ4YWM3ZSIsIkRldmljZUlkIjoiQkI3M0EzQUQtRUVDRS00RTc0LUE3QTMtQkVFNEZGOEM1M0JGIiwiRXhwaXJhdGlvblRpbWUiOiIyMDI1LTAzLTEwVDA5OjI5OjUxLjQ1Mjg0NzVaIiwianRpIjoiNWQ1YmJhNTUtZGJiMC00YzZmLTg3YzQtNzFjZmY1YmU3YjMxIiwibmJmIjoxNzI2MDQ2OTkxLCJleHAiOjE3NDE1OTg5OTEsImlhdCI6MTcyNjA0Njk5MSwiaXNzIjoiaHR0cHM6Ly9saW5rZmlkZXYuYXNhY29yZS5jb20iLCJhdWQiOiJodHRwczovL2xpbmtmaWRldi5hc2Fjb3JlLmNvbSJ9.j5hf7IR2gZs2SyXpi-it00syD72vEzKxWyirJrsRQJ4&sessionid=1952286788&asaFiCode=2017905674&asaConsumerCode=113713333&dynamicLink=https%3A%2F%2Fasavault.page.link%2FLtrHp9prBnen66xT7&version=1.08",

  // "https://appdev.asavault.com/crb?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzMjkyIiwiVXNlck5hbWUiOiJtMTFAbWFpbC5jb20iLCJBc2FDb25zdW1lckNvZGUiOiIxODE4MjE2OTciLCJUb2tlblRhZyI6IjkwNmFmMWU0LTZkNWQtNGUyMi1hMzE3LTFlNjYzNzM0YWJjMyIsIkV4cGlyYXRpb25UaW1lIjoiMjAyNC0xMS0yN1QwOTo0Nzo0MC40Njc3Njc5WiIsImp0aSI6IjFjMDJlN2RhLTQwODItNGYyMi04Mzk0LTdjYjBhN2E0MzZhZCIsIm5iZiI6MTcxNzE0ODg2MCwiZXhwIjoxNzMyNzAwODYwLCJpYXQiOjE3MTcxNDg4NjAsImlzcyI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSJ9.-zjI8xQTJRGTUC82X9f2Di4tEV4OhoQ1Bx4H-BvjvOM&sessionid=620636830&asaFiCode=2017905674&asaConsumerCode=63572153&dynamicLink=https%3A%2F%2Fasavault.page.link%2FnDQMwJdmg64kfQAv6&version=1.08",

// "https://appdev.asavault.com/hireandfirekids?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzMDExIiwiVXNlck5hbWUiOiJNMTBAbWFpbC5jb20iLCJBc2FDb25zdW1lckNvZGUiOiI0NjM4MjI4MDUiLCJUb2tlblRhZyI6ImVjODY3YmY1LTgxYzItNDMxOS1hYjBlLThjZGU3MmIxNDUwZSIsIkRldmljZUlkIjoiQkI3M0EzQUQtRUVDRS00RTc0LUE3QTMtQkVFNEZGOEM1M0JGIiwiRXhwaXJhdGlvblRpbWUiOiIyMDI1LTAzLTA4VDE1OjE1OjQ4LjQ5MjI1NTRaIiwianRpIjoiNzdiZmVmZmQtNDgxYS00ZTAyLWE4M2YtZTM0MzE4YmUxMDgyIiwibmJmIjoxNzI1ODk0OTQ4LCJleHAiOjE3NDE0NDY5NDgsImlhdCI6MTcyNTg5NDk0OCwiaXNzIjoiaHR0cHM6Ly9saW5rZmlkZXYuYXNhY29yZS5jb20iLCJhdWQiOiJodHRwczovL2xpbmtmaWRldi5hc2Fjb3JlLmNvbSJ9.lTuPIh3ARu8QyFZXKPN5cPbkAx3lTCV92uSoM4RosQQ&sessionid=591643269&asaFintechCode=1479515214&asaConsumerCode=1619238039&dynamicLink=https%3A%2F%2Fasavault.page.link%2F7Wd9TVfegsJbJ8Ng8&version=1.08",
//  "https://appdev.asavault.com/guac?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzNDQ1IiwiVXNlck5hbWUiOiJneWFuZXNoLmthbWFsQGFzYS5maW5hbmNpYWwiLCJBc2FDb25zdW1lckNvZGUiOiIxNDIyNTMxODkiLCJUb2tlblRhZyI6IjIxNWM3ZDBjLTJhNDYtNGQ5My04OWU3LTViZjRjMTk1MzBhMyIsIkRldmljZUlkIjoiZjM2ZGQ2MWZlOTAyYWVlZCIsIkV4cGlyYXRpb25UaW1lIjoiMjAyNS0wMS0xN1QwNjoxNTozOS4zMDIzMzc5WiIsImp0aSI6IjQ2YmI3YTBhLWQwZTktNGNjOC1iNTMyLTc1NzkwNmI0NDYyYiIsIm5iZiI6MTcyMTU0MjUzOSwiZXhwIjoxNzM3MDk0NTM5LCJpYXQiOjE3MjE1NDI1MzksImlzcyI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSJ9.dXgE19hmc2QVcKZKbAcbqUfcFd1qYALpMOOb7wcuNY0&sessionid=2491068&asaFintechCode=1282849331&asaConsumerCode=250152904&dynamicLink=https://asavault.page.link/eCKt7N8DCBYgTNaFA&version=1.08",
// "https://appdev.asavault.com/onegoal?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzNDQ1IiwiVXNlck5hbWUiOiJneWFuZXNoLmthbWFsQGFzYS5maW5hbmNpYWwiLCJBc2FDb25zdW1lckNvZGUiOiIxNDIyNTMxODkiLCJUb2tlblRhZyI6IjIxNWM3ZDBjLTJhNDYtNGQ5My04OWU3LTViZjRjMTk1MzBhMyIsIkRldmljZUlkIjoiZjM2ZGQ2MWZlOTAyYWVlZCIsIkV4cGlyYXRpb25UaW1lIjoiMjAyNS0wMS0xN1QwNjoxNTozOS4zMDIzMzc5WiIsImp0aSI6IjQ2YmI3YTBhLWQwZTktNGNjOC1iNTMyLTc1NzkwNmI0NDYyYiIsIm5iZiI6MTcyMTU0MjUzOSwiZXhwIjoxNzM3MDk0NTM5LCJpYXQiOjE3MjE1NDI1MzksImlzcyI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSJ9.dXgE19hmc2QVcKZKbAcbqUfcFd1qYALpMOOb7wcuNY0&sessionid=1886814206&asaFintechCode=1054208782&asaConsumerCode=2074603900&dynamicLink=https://asavault.page.link/eCKt7N8DCBYgTNaFA&version=1.08",  rupeeSymbol: "\u20B9",
};

